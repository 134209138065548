import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import EventEmitter from "./EventEmitter";
import { ethers } from "ethers";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import LogoMenace from "../img/logo_MS.png";

import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import _Web3 from "web3";

const APP_NAME = "Into The Dark";
const APP_LOGO_URL = LogoMenace;
const DEFAULT_ETH_JSONRPC_URL =
  "https://mainnet.infura.io/v3/1f95b3b614d54e1cb5a9f0e763d03ead";
const DEFAULT_CHAIN_ID = 1;

export default class WalletConnectClass extends EventEmitter {
  constructor(experience) {
    super();

    this.experience = experience;

    this.isInitCoinbase = false;
    this.isInitWalletConnect = false;

    document.getElementById("walletconnect").addEventListener("click", () => {
      if (this.isInitWalletConnect === false) {
        this.isInitWalletConnect = true;
        this.init();
      }
    });

    document.getElementById("coinbaseconnect").addEventListener("click", () => {
      if (this.isInitCoinbase === false) {
        this.isInitCoinbase = true;
        this.initCoinbase();
      }
    });

    // Create a connector
    this.connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });

    if (this.connector.connected) {
      this.checkAlchemy(this.connector);
    }
  }

  initCoinbase() {
    console.log("test");
    const coinbaseWallet = new CoinbaseWalletSDK({
      appName: APP_NAME,
      appLogoUrl: APP_LOGO_URL,
      darkMode: true,
    });

    // Initialize a Web3 Provider object
    const ethereum = coinbaseWallet.makeWeb3Provider(
      DEFAULT_ETH_JSONRPC_URL,
      DEFAULT_CHAIN_ID
    );

    // Initialize a Web3 object
    const web3 = new _Web3(ethereum);

    ethereum.request({ method: "eth_requestAccounts" }).then((response) => {
      const accounts = response;

      // Optionally, have the default account set for web3.js
      web3.eth.defaultAccount = accounts[0];
    });

    // Alternatively, you can use ethereum.enable()
    ethereum.enable().then((accounts) => {
      web3.eth.defaultAccount = accounts[0];

      this.checkAlchemy(accounts[0]);
    });
  }

  async checkAlchemy(payload) {
    const url =
      "https://polygon-mainnet.g.alchemy.com/v2/" + process.env.ALCHEMY;

    // Using web3js
    const web3 = createAlchemyWeb3(url);

    // Using ethers.js
    const provider = new ethers.providers.JsonRpcProvider(url);

    const blockNumber = await web3.eth.getBlockNumber();

    let ownerAddr = null;

    if (typeof payload === "string" || payload instanceof String) {
      ownerAddr = payload;
    } else {
      if (payload._accounts) {
        ownerAddr = payload?._accounts[0];
      } else {
        ownerAddr = payload.params[0].accounts[0];
      }

      if (payload._peerMeta) {
        this.experience.walletType = payload._peerMeta.description;
      }
    }

    const nfts = await web3.alchemy.getNfts({
      owner: ownerAddr,
    });

    let getNFT = false;

    // Print contract address and tokenId for each NFT:
    for (const nft of nfts.ownedNfts) {
      if (
        nft.contract.address === "0x2af74e7689717bda074e4aca0ae30bc00342ab68"
      ) {
        getNFT = true;
        this.experience.userWalletID = ownerAddr;
        this.experience.imageURL =
          "https://ipfs.io/ipfs/" + nft.metadata.image.substring(7);
      }
    }

    if (getNFT) {
      this.experience.UI.setSlider();
      document.getElementById("fullContent").style.position = "fixed";
      document.getElementById("fullContent").style.height = "100%";
      document.getElementById("homepageStart").innerHTML = "Chargement...";
      this.experience.init();
      document.getElementById("choisewallet").style.display = "none";

      window.digitalData = {
        settings: {
          reportSuites: "wmg,wmgfr",
        },
        page: {
          pageInfo: {
            pageName:
              "menace Santana:Into The Dark 3D NFT Game:Landing:NFT Validated",
            server: "menace Santana:Site",
            platform: "MIS Custom Page",
            devTeam: "Warner Music France",
          },
          category: {
            primaryCategory: "menace Santana:Landing Page",
            pageType: "Landing Page:NFT Game",
          },
        },
        content: {
          artist: "menace Santana",
          label: "Warner Music International",
          sublabel: "Warner Music France",
        },
      };

      document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
    } else {
      alert("Vous n'avez pas le NFT demandé");
    }
  }

  init() {
    this.on("validated", () => {
      this.checkAlchemy(this.payload);
    });

    // Check if connection is already established
    if (!this.connector.connected) {
      // create new session
      this.connector.createSession();
    } else {
      this.payload = this.connector;
      this.trigger("validated");
    }

    // Subscribe to connection events
    this.connector.on("connect", (error, payload) => {
      this.payload = payload;
      this.trigger("validated");

      if (error) {
        throw error;
      }
    });

    this.connector.on("session_update", (error, payload) => {
      if (error) {
        throw error;
      }
    });

    this.connector.on("disconnect", (error, payload) => {
      if (error) {
        throw error;
      }
    });
  }
}
