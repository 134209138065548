import Experience from './Experience'
import gsap from 'gsap'
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import '@splidejs/splide/css/core';

export default class UI {
    constructor() {
        this.experience = new Experience()

        this.selected = null
        this.isMobile = this.experience.isMobile

        this.init()
    }

    init() {
        this.setHomepageScreen()
        document.querySelector('.splide').style.display = "none"
        this.setTransitions()
        this.setEndPageScreen()
    }

    setSlider() {
        document.querySelector('.splide').style.display = "initial"
        Splide.defaults = {
            autoplay: true,
            arrows: true,
            // autoHeight: true,
            loop: true,
            lazyLoad: true,
            pauseOnHover: false,
            pauseOnFocus: false
            // updateOnMove : true,
        }
        new Splide( '.splide', {
            type: 'loop'
        } ).mount();

        

        window.digitalData={									
            settings:{								
                reportSuites:"wmg,wmgfr"							
            },								
            page:{								
                pageInfo:{							
                    pageName:"menace Santana:Into The Dark 3D NFT Game:Landing:Tutorial",						
                    server:"menace Santana:Site",						
                    platform:"MIS Custom Page",						
                    devTeam:"Warner Music France"						
                },							
                category:{							
                    primaryCategory:"menace Santana:Landing Page",						
                    pageType:"Landing Page:NFT Game"						
                }							
            },								
            content:{								
                artist:"menace Santana",							
                label:"Warner Music International",							
                sublabel:"Warner Music France"							
            }								
        }						
          
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));
    }

    setEndPageScreen() {
        this.endPage = {}

        this.endPage.screen = document.getElementById('endScreen')
        this.endPage.screen.isOpen = false


        

        window.digitalData={									
            settings:{								
                reportSuites:"wmg,wmgfr"							
            },								
            page:{								
                pageInfo:{							
                    pageName:"menace Santana:Into The Dark 3D NFT Game:Landing:End",						
                    server:"menace Santana:Site",						
                    platform:"MIS Custom Page",						
                    devTeam:"Warner Music France"						
                },							
                category:{							
                    primaryCategory:"menace Santana:Landing Page",						
                    pageType:"Landing Page:NFT Game"						
                }							
            },								
            content:{								
                artist:"menace Santana",							
                label:"Warner Music International",							
                sublabel:"Warner Music France"							
            }								
        }															

        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));

        document.getElementById('reecouter').addEventListener('click', () => {
            window.location.reload()
        })
    }

    openEndPage() {
        this.endPage.screen.style.opacity = 1
        this.endPage.screen.style.pointerEvents = "initial"
    }

    setHomepageScreen() {
        this.homepage = {}
        
        this.homepage.screen = document.getElementById('homepage')
        this.homepage.screen.isOpen = false
        this.homepage.btn = document.getElementById('homepageStart')
        // this.homepage.btn.style.display = "none"

        this.homepage.screen.close = () => {
            this.homepage.screen.isOpen = true
            this.homepage.screen.style.display = "none"
            // this.experience.world.character.animation.play('gettingUp')
            // this.experience?.world?.arcadeMachine?.video?.play()
        }

        this.homepage.screen.show = () => {
            this.homepage.isOpen = true
            this.homepage.screen.style.display = "flex"
        }

        if ((/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) === false) {
            document.querySelector('.chromeAdvice').classList.add('active')
        }

        function iOS() {
            return ['iPad Simulator','iPhone Simulator','iPod Simulator','iPad','iPhone','iPod'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }

        if (iOS() === true) {
            document.querySelector('.silent').classList.add('active')
        }
    }

    startXP() {
        window.digitalData={								
            settings:{							
                reportSuites:"wmg,wmgfr"						
            },							
            page:{							
                pageInfo:{						
                    pageName:"menace Santana:Into The Dark 3D NFT Game:Landing:Game",					
                    server:"menace Santana:Site",					
                    platform:"MIS Custom Page",					
                    devTeam:"Warner Music France"					
                },						
                category:{						
                    primaryCategory:"menace Santana:Landing Page",					
                    pageType:"Landing Page:NFT Game"					
                }						
            },							
            content:{							
                artist:"menace Santana",						
                label:"Warner Music International",						
                sublabel:"Warner Music France"						
            }							
        }								

        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));

        this.homepage.screen.close()
        this.experience.sounds.play('Cellar')
        this.content = document.getElementById('fullContent')
        this.content.style.pointerEvents = "none"
        this.content.style.background = "none"
        document.querySelector('html').style.overflow = "hidden"
        document.body.style.overflow = "hidden"
        this.content.style.overflow = "hidden"
        this.content.style.position = "absolute";

        this.experience.world.character.animation.play('gettingUp')
        this.experience.world.character.animation.actions.current.paused = true
        this.experience.world.character.lockUserActions()

        if (this.experience.isMobile) {
            document.getElementById('outer-mobile_control').classList.add('active')
        }

        document.getElementById('introVideoContainer').style.opacity = 1
        
        if (window.innerWidth < 800) {
            document.getElementById('introVideo2').play()
            document.getElementById('introVideo').style.display = "none"
        } else {
            document.getElementById('introVideo').play()
            document.getElementById('introVideo2').style.display = "none"
        }


        // TO DECOMMENT

        // document.getElementById('introVideo').currentTime = document.getElementById('introVideo').duration - 0.001

        const endVideo = () => {
            document.getElementById('introVideoContainer').style.opacity = 0
            setTimeout(() => {
                // this.screamer.removeBlackScreen()

                setTimeout(() => {
                    this.experience.world.character.animation.actions.current.paused = false
                }, 1000)
            }, 500)

            gsap.to(this.experience.world.environment.environmentMap, {
                intensity: 0.6,
                duration: 2,
                delay: 1,
                onUpdate: () => {
                    this.experience.world.environment.environmentMap.updateMaterials()
                },
                onComplete: () => {
                    this.experience.world.lamp?.initDOM(-8.84, -1.3, 22.62, -10.3, 22.22, -Math.PI/2)
                    this.experience.world.character.isLockedUserActions = false
                    // this.experience.world.clignoteLight()
                }
            })
            

            this.experience.world.environment.environmentMap.intensity = 0.0
            this.experience.world.environment.environmentMap.updateMaterials()
        }

        document.getElementById('introVideo').addEventListener("ended", () => {
            endVideo()
        });

        document.getElementById('introVideo2').addEventListener("ended", () => {
            endVideo()
        });

            // this.experience.audioPlayer.part1Sound.play()
            // this.experience.world.character.animation.play('gettingUp')
            // this.isMobile === false && this.experience?.camera?.pointerLockControls?.controls.lock()
    }

    clearSelected() {       
        if (document.querySelector('.active')) {
            document.querySelector('.active').classList.remove('active')
        }
    }
    
    setTransitions() {
        this.transitions = {}
        this.transitions.screen = document.getElementById('transitionScreen')
    }

    showEndDemo() {

    }

    setBlackScreen() {
        this.transitions.screen.classList.add('in', 'black')
    }

    setWhiteScreen() {
        this.transitions.screen.classList.add('in', 'white')
    }

    fadeOutScreen() {
        this.transitions.screen.classList.remove('black', 'in', 'white', 'transparent')
    }

    initPoints() {
        // document.getElementById('doorPoint').addEventListener('click', () => {
        //     this.experience.world.door9.animation.actions.idle.play()
        //     // document.getElementById('doorPoint').classList.remove('visible')
        //     // this.experience.points.pop()
        // })
    }
}