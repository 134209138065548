import * as THREE from 'three'
import Experience from '../Experience.js'
import Item from './Item.js'
import VertexTV from '../Shaders/oldTV/vertex.glsl'
import FragmentTV from '../Shaders/oldTV/fragment.glsl'

export default class TV extends Item
{
    constructor(itemName, position, rotation, scale, colorMap, roughnessMap, normalMap, onClick, unlockAction, isMeshBasicMaterial, directOnclick)
    {
        super(itemName, position, rotation, scale, colorMap, roughnessMap, normalMap, onClick, unlockAction, isMeshBasicMaterial, directOnclick)
        // this.setScreen()
        // this.setTVMaterial()
    }

    setTVMaterial() {
        this.TVMaterial = new THREE.ShaderMaterial({
            precision: 'lowp',
            uniforms: {
                tDiffuse: 	{ type: "t", value: null },
                time: 		{ type: "f", value: 0.0 },
                distortion:   { type: "f", value: 3.0 },
                distortion2:  { type: "f", value: 5.0 },
                speed:     	{ type: "f", value: 0.2 },
                rollSpeed:    { type: "f", value: 0.1 },
            },
            vertexShader: VertexTV,
            fragmentShader: FragmentTV
        })
    }

    setScreen() {
        this.model.children[0].children[0].children[0].material = this.TVMaterial
        this.model.children[0].children[0].children[0].children[0].material = this.TVMaterial
    }


    update()
    {
        // this.animation.mixer.update(this.time.delta * 0.001)
        if (this.TVMaterial)
            this.TVMaterial.uniforms.time.value = this.experience.time.elapsed
    }
}