import TV_Soundtrack from '../../assets/audio/TV_Sound.mp3'
import Portes from '../../assets/audio/portes.mp3'
import Garage from '../../assets/audio/garage2.mp3'
import Footstep1 from '../../assets/audio/walk1.mp3'
import Footstep2 from '../../assets/audio/walk2.mp3'
import Footstep3 from '../../assets/audio/walk3.mp3'
import Footstep4 from '../../assets/audio/walk4.mp3'
import Footstep5 from '../../assets/audio/walk5.mp3'
import Footstep6 from '../../assets/audio/walk6.mp3'
import HeightBitSlow from '../../assets/audio/Fkmelo-slow.mp3'
import HeightBitMid from '../../assets/audio/Fkmelo-mid.mp3'
import HeightBitFast from '../../assets/audio/Fkmelo-fast.mp3'
import LightNeon from '../../assets/audio/lightNeon.mp3'
import Screamer from '../../assets/audio/screamer.mp3'
import RadioGenerique from '../../assets/audio/RadioGenerique.mp3'
import Cellar from '../../assets/audio/cellar.mp3'
import IlEtaitUneFois from '../../assets/audio/ieuf.mp3'
import EndGlitch from '../../assets/audio/Fin_glitch_Sound.mp3'
import TerminalAmbient from '../../assets/audio/terminal_w_bass.mp3'

const soundsObj = [
    {
        name: 'HeightBitSlow',
        sounds: [HeightBitSlow],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: true
    },
    {
        name: 'HeightBitMid',
        sounds: [HeightBitMid],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: true
    },
    {
        name: 'HeightBitFast',
        sounds: [HeightBitFast],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: true
    },
    {
        name: 'IlEtaitUneFois',
        sounds: [IlEtaitUneFois],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: true
    },
    {
        name: 'Portes',
        sounds: [Portes],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
    {
        name: 'Garage',
        sounds: [Garage],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
    {
        name: 'Cellar',
        sounds: [Cellar],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: true
    },
    {
        name: 'Radio',
        sounds: [RadioGenerique],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
    {
        name: 'LightNeon',
        sounds: [LightNeon],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
    {
        name: 'Screamer',
        sounds: [Screamer],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
    {
        name: 'FootStep',
        sounds: [Footstep1, Footstep2, Footstep3, Footstep4, Footstep5, Footstep6],
        minDelta: 30,
        velocityMin: 0,
        velocityMultiplier: .8,
        volumeMin: 1,
        volumeMax: .5,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
    {
        name: 'EndGlitch',
        sounds: [EndGlitch],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
    {
        name: 'TerminalAmbient',
        sounds: [TerminalAmbient],
        minDelta: 100,
        velocityMin: 0,
        velocityMultiplier: 1,
        volumeMin: 1,
        volumeMax: 1,
        rateMin: 1,
        rateMax: 1,
        loop: false
    },
]

export default soundsObj