import * as THREE from 'three'
import VertexArcadeMachineShader from '../Shaders/arcadeMachine/vertex.glsl'
import FragmentArcadeMachineShader from '../Shaders/arcadeMachine/fragment.glsl'
import Experience from '../Experience'
import Resources from '../Utils/Resources'

export default class ArcadeMachineScreen {
    constructor() {
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.debug = this.experience.debug.ui

        this.init()
    }

    init() {
        this.setMaterial()
        // this.setGeometry()
        // this.setMesh()
    }

    setMaterial() {
        this.material = new THREE.ShaderMaterial({
            precision: 'lowp',
            uniforms: {
                texture1: { value: this.resources.items.arcadeMachineMask },
                texture2: { value: this.resources.items.arcadeMachineMask },
                time: { value: 0 },
                tDiffuse: 	{ type: "t", value: null },
                distortion:   { type: "f", value: 200.0 },
                distortion2:  { type: "f", value: 5.0 },
                speed:     	{ type: "f", value: 0.1 },
                rollSpeed:    { type: "f", value: 0.1 },
                u_zoom:    { type: "f", value: 1.0 },
                u_resolution: {type: "v2", value: new THREE.Vector2(16, 9)}
            },
            transparent: false,
            vertexShader: VertexArcadeMachineShader,
            fragmentShader: FragmentArcadeMachineShader
        })

        if (this.experience.debug.active) {
            this.debug.add(this.material.uniforms.distortion, 'value').min(-5).max(15)
        }
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(3, 3)
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.experience.scene.add(this.mesh)
        this.mesh.position.y = -2
        this.mesh.rotation.y = Math.PI
    }

    update() {
        this.material.uniforms.time.value = this.experience.time.elapsed
    }
}