export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
                'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {
        name: 'DoorModel',
        type: 'gltfModel',
        path: 'models/Door/DoorModel.glb'
    },
    {
        name: 'DoorColor',
        type: 'texture',
        path: 'models/Door/DoorColor2.jpg'
    },
    {
        name: 'WallsModelLight',
        type: 'gltfModel',
        path: 'models/ImmeubleCave/system3.glb'
    },
    {
        name: 'WallsLightmap',
        type: 'texture',
        path: 'models/ImmeubleCave/LightmapCadro4.jpg'
    },
    {
        name: 'ConcreteFloorColor',
        type: 'texture',
        path: 'models/ImmeubleCave/Concrete023_1K-JPG/Concrete023_1K_Color.jpg'
    },
    {
        name: 'menaceModel',
        type: 'gltfModel',
        path: 'models/SimpleCharacter/menace_anims9.glb'
    },
    {
        name: 'arcadeMachineModel',
        type: 'gltfModel',
        path: 'models/Items/arcade_machine/arcade.glb'
    },
    {
        name: 'arcadeMachineColor',
        type: 'texture',
        path: 'models/Items/arcade_machine/textures/Material_0_diffuse.png'
    },
    {
        name: 'menaceModelImage',
        type: 'texture',
        path: 'models/SimpleCharacter/charactere_texture_whiteB.jpg'
    },
    {
        name: 'arcadeMachineMask',
        type: 'texture',
        path: 'img/arcade_screen_1.png'
    },
    {
        name: 'interrupeurModel',
        type: 'gltfModel',
        path: 'models/Items/interrupeutModel.glb'
    },
    {
        name: 'armoireModel',
        type: 'gltfModel',
        path: 'models/Armoire/WoodenTable_03_1k.gltf/WoodenTable_03_1k_B.gltf'
    },
    {
        name: 'radioModel',
        type: 'gltfModel',
        path: 'models/Items/1990_tv/scene.gltf'
    },
    {
        name: 'powerBox',
        type: 'gltfModel',
        path: 'models/power_box_01_1k.gltf/power_box_01_1k.gltf'
    },
    {
        name: 'scifiLight',
        type: 'gltfModel',
        path: 'models/scifi_light_11/scene.gltf'
    },
    {
        name: 'veloModel',
        type: 'gltfModel',
        path: 'models/old_bicycle/scene2.gltf'
    },
    {
        name: 'NFTRoom_1_Model',
        type: 'gltfModel',
        path: 'models/NFTRoom/NFTRoom_1.glb'
    },
    {
        name: 'NFTRoom_1_Texture',
        type: 'texture',
        path: 'models/NFTRoom/NFT_Room_1.jpg'
    },
    {
        name: 'NFTRoom_2_Model',
        type: 'gltfModel',
        path: 'models/NFTRoom/NFTRoom_2C.glb'
    },
    {
        name: 'NFTRoom_2_Texture',
        type: 'texture',
        path: 'models/NFTRoom/NFT_Room_2A.jpg'
    },
    {
        name: 'PC_Model',
        type: 'gltfModel',
        path: 'models/NFTRoom/PC.glb'
    },
    {
        name: 'PC_Texture',
        type: 'texture',
        path: 'models/NFTRoom/PCBake2.jpg'
    },
    {
        name: 'DevRoomModel',
        type: 'gltfModel',
        path: 'models/DevRoom/DevRoom_3.glb'
    },
    {
        name: 'DevRoomTexture',
        type: 'texture',
        path: 'models/DevRoom/SalleDevMap_1_2K_B.jpg'
    },
    // {
    //     name: 'DevRoomScreen1Texture',
    //     type: 'texture',
    //     path: 'models/DevRoom/ecran1_fin.png'
    // },
    // {
    //     name: 'DevRoomScreen2Texture',
    //     type: 'texture',
    //     path: 'models/DevRoom/ecran2_fin.png'
    // },
    // {
    //     name: 'DevRoomScreen3Texture',
    //     type: 'texture',
    //     path: 'models/DevRoom/ecran3_fin.png'
    // },
    {
        name: 'CartonsRoomModel',
        type: 'gltfModel',
        path: 'models/RandomRoom/Cartons1_B.glb'
    },
    {
        name: 'CartonsRoomTexture',
        type: 'texture',
        path: 'models/RandomRoom/RandomCartons1_B.jpg'
    },
    
    {
        name: 'CartonsRoomModel2',
        type: 'gltfModel',
        path: 'models/RandomRoom/Cartons2_B.glb'
    },
    {
        name: 'CartonsRoomTexture2',
        type: 'texture',
        path: 'models/RandomRoom/RandomCartons2_A.jpg'
    },
    {
        name: 'DisquetteModel',
        type: 'gltfModel',
        path: 'models/Disquette/DisquetteBake.glb'
    },
    {
        name: 'DisquetteTexture',
        type: 'texture',
        path: 'models/Disquette/Disquette.jpg'
    },
    {
        name: 'ScreamerRoomModel',
        type: 'gltfModel',
        path: 'models/ScreamerRoom/ScreamerRoom_Items2.glb'
    },
    {
        name: 'ScreamerRoomTexture',
        type: 'texture',
        path: 'models/ScreamerRoom/SalleScreamer2.jpg'
    },
    {
        name: 'GarageDoorModel',
        type: 'gltfModel',
        path: 'models/ImmeubleCave/garage_door.glb'
    },
    {
        name: 'ColisionsModel',
        type: 'gltfModel',
        path: 'models/colisions2.glb'
    },
    {
        name: 'BloodyHand',
        type: 'texture',
        path: 'img/blood_hand.png'
    },
    {
        name: 'Objects1Model',
        type: 'gltfModel',
        path: 'models/RandomRoom/Objects1B.glb'
    },
    {
        name: 'BD',
        type: 'gltfModel',
        path: 'models/Items/bd.glb'
    },
    {
        name: 'Disque',
        type: 'gltfModel',
        path: 'models/Items/disquePile.glb'
    },
    {
        name: 'Objects1Texture',
        type: 'texture',
        path: 'models/RandomRoom/Objects1.jpg'
    },
    {
        name: 'Objects2Model',
        type: 'gltfModel',
        path: 'models/RandomRoom/Objects2C.glb'
    },
    {
        name: 'Objects2Texture',
        type: 'texture',
        path: 'models/RandomRoom/Objects2.jpg'
    },
    {
        name: 'PoubellesModel',
        type: 'gltfModel',
        path: 'models/Items/Poubelles/poubellesModel.glb'
    },
    {
        name: 'PoubellesTexture',
        type: 'texture',
        path: 'models/Items/Poubelles/poubellesTexture_V1.jpg'
    },
    {
        name: 'JournauxModel',
        type: 'gltfModel',
        path: 'models/Items/journaux/scene.gltf'
    },
    {
        name: 'ButterflyKnife',
        type: 'gltfModel',
        path: 'models/Items/ps1_style_butterfly_knife/scene.gltf'
    },
    {
        name: 'ButterflyKnifeTexture',
        type: 'texture',
        path: 'models/Items/ps1_style_butterfly_knife/textures/Material_baseColor.png'
    },
    {
        name: 'PapierModel',
        type: 'gltfModel',
        path: 'models/Items/Papier/papierModel.glb'
    },
    {
        name: 'secondArcadeScreen',
        type: 'texture',
        path: 'models/Items/arcade_machine/MS_BORNE.jpg'
    },
    {
        name: 'thirdArcadeScreen',
        type: 'texture',
        path: 'models/Items/arcade_machine/MS_BORNE2.jpg'
    },
    {
        name: 'Salle3Ecran1',
        type: 'texture',
        path: 'img/ecran1_3.png'
    },
    {
        name: 'Salle3Ecran2',
        type: 'texture',
        path: 'img/ecran2_3.png'
    },
]