import * as THREE from "three";

import Debug from "./Utils/Debug.js";
import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import Renderer from "./Renderer.js";
import World from "./World/World.js";
import Resources from "./Utils/Resources.js";
import sources from "./sources.js";
import UI from "./UI.js";
import ThirdPersonCamera from "./ThirdPersonCamera.js";
import WalletConnectClass from "./Utils/WalletConnectClass.js";
import Sounds from "./Utils/Sounds.js";

let instance = null;

export default class Experience {
  constructor(_canvas) {
    // Singleton
    if (instance) {
      return instance;
    }
    instance = this;

    // Global access
    window.experience = this;

    // Options
    this.collidableMeshList = [];

    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // this.isMobile = true
    // Setup
    this.debug = new Debug();
    this.sizes = new Sizes();
    this.time = new Time();
    this.sounds = new Sounds();
    this.scene = new THREE.Scene();
    this.UI = new UI();

    this.canvas = _canvas;

    if (this.debug.noNFT) {
      this.UI.setSlider();
      document.getElementById("fullContent").style.position = "absolute";
      document.getElementById("homepageStart").innerHTML = "Chargement...";
      document.getElementById("fullContent").classList.remove("off");
      this.init();
      document.getElementById("choisewallet").style.display = "none";
    } else {
      if (this.debug.active === false) {
        this.walletConnectTest = new WalletConnectClass(this);
      }

      document.getElementById("fullContent").classList.remove("off");

      if (this.debug.active) {
        setTimeout(() => {
          this.init();
        }, 2000);
      }
    }
  }

  init() {
    this.resources = new Resources(sources);
    this.camera = new ThirdPersonCamera();
    this.renderer = new Renderer();
    this.world = new World();

    this.PointRaycaster = new THREE.Raycaster();

    // Resize event
    this.sizes.on("resize", () => {
      this.resize();
    });

    // Time tick event
    this.time.on("tick", () => {
      this.update();
    });

    this.points = [
      {
        position: new THREE.Vector3(36.19, -1.5, 19.5),
        element: document.querySelector(".point-0"),
      },
    ];

    this.interactiveElements = [];
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
  }

  update() {
    if (this.debug.active) {
      this.debug.stats.begin();
    }

    if (this.world?.character?.canCollideList) {
      for (const point of this.points) {
        const pointDistance = point.position.distanceTo(
          this.world.character.model.position
        );

        if (pointDistance < 10) {
          const screenPosition = point.position.clone();
          screenPosition.project(this.camera.instance);

          const translateX = screenPosition.x * this.sizes.width * 0.5;
          const translateY = -screenPosition.y * this.sizes.height * 0.5;
          point.element.style.transform = `translateX(calc(${translateX}px - 50%)) translateY(calc(${translateY}px - 50%))`;

          this.PointRaycaster.setFromCamera(
            screenPosition,
            this.camera.instance
          );
          const intersects = this.PointRaycaster.intersectObjects(
            this.world.character.canCollideList,
            true
          );

          if (intersects.length === 0) {
            point.element.classList.add("visible");
          } else {
            const intersectionDistance = intersects[0].distance;

            if (intersectionDistance < pointDistance) {
              point.element.classList.remove("visible");
            } else {
              point.element.classList.add("visible");
            }
          }
        } else {
          point.element.classList.remove("visible");
        }
      }
    }

    this.camera && this.camera.update();
    this.world.update();
    this.renderer.update();

    if (this.debug.active) {
      this.debug.stats.end();
    }
  }

  removeDOM() {
    document.getElementById("doorPoints").remove();
    document.getElementById("fullContent").innerHTML += `
            <div id="doorPoints">
                <div class="point point-0">
                    <div class="label">1</div>
                    <div class="text">Lorem ipsum, dolor sit amet consectetur adipisicing elit</div>
                </div>
            </div>
        `;
  }

  destroy() {
    this.sizes.off("resize");
    this.time.off("tick");

    const clearThree = (obj) => {
      while (obj.children.length > 0) {
        clearThree(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach((prop) => {
          if (!obj.material[prop]) return;
          if (
            obj.material[prop] !== null &&
            typeof obj.material[prop].dispose === "function"
          )
            obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    };

    clearThree(this.scene);
  }
}
