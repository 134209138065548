import Experience from "./Experience";
// import DataHack from '../assets/data/hack'

export default class HackScreen {
    constructor() {
        this.experience = new Experience()
        
        this.data = [
            ["[root@localhost ~]# ", 0],
            ["cd ", 2000],
            ["trailer <br /><br />[root@localhost trailer]# ", 2100],
            ["npm ", 2600],
            ["run ", 2700],
            ["dev <br /><br />", 2900],
            ["> dev <br /><br />", 4000],
            ["webpack serve --config ./bundler/webpack.dev.js --https<br />", 4200],
            ["Project running at: <br />", 4500],
            ["https://localhost:8080 <br /><br />", 4600],
            ["<br />", 5200],
            ["<br /> <br /><br />", 5500],
            [".", 5630],
            [".", 5740],
            [".", 5740],
            [".", 5840],
            [".", 5860],
            [".", 5880],
            [".", 5900],
            [".", 5920],
            [".", 5940],
            [".", 5960],
            [".", 5980],
            [".", 6000],
            [".", 6020],
            [".", 6040],
            [".", 6060],
            [".", 6080],
            [".", 6100],
            ["<br />", 6100],
            ["?&€%#.<br />", 6150],
            ["?&€%#.<br />", 6200],
            ["?&€%#.<br />", 6250],
            ["?&€%#.<br />", 6300],
            ["?&€%#.<br />", 6350],
            ["?&€%#.<br />", 6400],
            ["?&€%#.<br />", 6450],
            ["?&€%#.<br />", 6500],
            ["?&€%#.<br />", 6550],
            ["?&€%#.<br />", 6600],
            ["?&€%#.<br />", 6650],
            ["?&€%#.<br />", 6700],
            ["?&€%#.<br />", 6750],
            ["<br /><br />", 6850],
            ["loading", 6900],
            [".", 6920],
            [".", 6940],
            [".", 6960],
            [".", 6980],
            [".", 7000],
            [".", 7020],
            [".", 7040],
            [".", 7070],
            [".", 7080],
            [".", 7100],
            [".", 7120],
            [".", 7140],
            [".", 7160],
            [".", 7180],
            [".", 7200],
            [".", 7220],
            [".", 7240],
            [".", 7260],
            [".", 7280],
            [".", 7300],
            [".", 7320],
            [".", 7340],
            [".", 7360],
            [".", 7380],
            [".", 7400],
            [".", 7420],
            [".", 7440],
            ["<br /><br />", 8000],
            ["<br /><br />", 9000],
            ["Loaded<br /><br />", 10000],
            ["User identification...<br />", 11500],
            ["Wallet found<br />", 12500],
            ["<br />", 13000],
            ["Wallet : ", 13500],
            [this.experience.walletType, 14000],
            ["<br />", 15000],
            ["<br />", 15500],
            ["Wallet intrusion ID : ", 16000],
            [this.experience.userWalletID + "<br />", 16500],
            ["Asset found : " + this.experience.imageURL, 17500],
            ["<br /><br />", 18500],
            ["User localisation", 19000],
            [".", 20600],
            [".", 20610],
            [".", 20620],
            [".", 20630],
            [".", 20640],
            [".", 20650],
            [".", 20660],
            [".", 20670],
            [".", 20680],
            [".", 20690],
            [".", 20700],
            ["User found<br />", 21000],
            ["<br />", 21200],
            ["Wallet assets destroyed<br />", 22000],
            ["User vanished<br />", 24000],
            ["<br />", 25000],
            ["Wallet vanished<br />", 26000],
            ["<br />", 27200],
            ["/endProgram", 28400],
            ["<br />", 28600],
            ["experience", 28800],
            [".", 28900],
            ["world", 29000],
            [".", 29200],
            ["insert_disk()", 29400]
        ]

        this.init()
    }

    init() {
        this.initDOM()
        }

    initDOM() {
        this.hackScreenDOM = document.getElementById('hackScreen')
        this.contentHackScreenDOM = document.getElementById('contentHack')
    }

    openDOM() {
        this.experience.world.clickRaycaster.openInteractiveDOM()
        this.experience.world.clickRaycaster.openHackScreen()

        var s = s_gi('wmg,wmgatl,wmgwallowsmusic.com');						
        s_dtm.linkTrackVars='prop1,eVar4';						
        s_dtm.prop1='menace Santana';						
        s_dtm.eVar4 = 'menace Santana';						
        s_dtm.tl(this,'o','menace Santana:Into The Dark 3D NFT Game:Landing:Open Console Click');						
    }

    closeDOM() {
            this.experience.world.clickRaycaster.closeInteractiveDOM()
    }

    initCode() {
        // this.experience.sounds.play('TerminalAmbient')
        this.data.forEach((line, index) => {
            setTimeout(() => {
                this.contentHackScreenDOM.innerHTML = document.getElementById('contentHack').innerHTML + line[0]
                this.contentHackScreenDOM.scrollIntoView({block: "end"});
            }, line[1]) 
        })

        
                    
        if (parseInt(localStorage.getItem('accessITD')) <= 3) {
            if (this.experience.sounds.currentSounds.HeightBitSlow) {
                this.experience.sounds.currentSounds.HeightBitSlow.pause()
            }
            
            if (this.experience.sounds.currentSounds.HeightBitMid) {
                this.experience.sounds.currentSounds.HeightBitMid.pause()
            }
    
            this.experience.sounds.play('HeightBitFast')
        }

        setTimeout(() => {
            this.closeDOM()
            this.isLockedUserActions = false
        }, 33000)

        if (this.experience.world.arcadeMachineStep !== 2) {
            setTimeout(() => {
                this.experience.world.endXP()
            }, 63000)
        } else {
            setTimeout(() => {
                this.experience.world.endXP()
            }, 33000)
        }
    }
}