import Experience from "../Experience";

export default class MailBox {
    constructor() {
        this.experience = new Experience()

        this.init()
    }

    setDatas() {
        this.datas = [
            {
                id: 1,
                title: "Informations production \"Into The Dark\"",
                emeteur: "titusinteractive@aol.com",
                text: `<p>Bonjour,<br /><br /><br />
                La production initiale de "Into The Dark" est de <b>5.169 exemplaires</b> distribués à notre réseau de revendeurs parisiens :<br /><br />
                <b>MICROMANIA</b><br />
                210, Porte Lescot<br />
                75001 Paris<br /><br />
                Qté : 1.000<br /><br /><br />
                <b>MICROMANIA - PRINTEMPS HAUSSMANN</b><br />
                64, boulevard Haussmann<br />
                75009 Paris<br /><br />
                Qté : 2.000<br /><br /><br />
                <b>SCORE-GAMES</b><br />
                17, rue des écoles<br />
                75005 Paris<br /><br />
                Qté : 500<br /><br /><br />
                <b>AMIE LE PRO</b><br />
                11, boulevard Voltaire<br />
                75011 Paris<br /><br />
                Qté : 300<br /><br /><br />
                <b>ELITENDO</b><br />
                179, boulevard Voltaire<br />
                75011 Paris<br /><br />
                Qté : 369<br /><br /><br />
                <b>MCCR</b><br />
                25, rue Jean Leclaire<br />
                75017 Paris<br /><br />
                Qté : 200<br /><br /><br />
                <b>STOCK GAMES</b><br />
                13-15, rue des écoles<br />
                75004 Paris<br /><br />
                Qté : 200<br /><br /><br />
                <b>ALLIA GAMES</b><br />
                42, rue Maubeuge<br />
                75009 Paris<br /><br />
                Qté : 300<br /><br /><br />
                <b>SHOOT AGAIN</b><br />
                145, rue de Flandres<br />
                75019 Paris<br /><br />
                Qté : 300<br /><br /><br />
                Comme convenu, si cette production initiale est écoulée dans les 45 jours suivant sa sortie, une nouvelle quantité sera définie ultérieurement pour être distribuée sur le reste du territoire.<br /><br />
                Cordialement,<br />
                Titus</p>
                `
            },
            {
                id: 2,
                title: "Re: Informations production \"Into The Dark\"",
                emeteur: "fakedreamstudios@aol.com",
                text: `<p>
                Bonjour,<br /><br />
                Merci pour ces informations.<br /><br />
                FDS</p>
                `
            },
            {
                id: 3,
                title: "Re: Informations production \"Into The Dark\"",
                emeteur: "titusinteractive@aol.com",
                text: `<p>Bonjour,<br /><br />
                Nous ne sommes pas parvenus à vous joindre par téléphone ce jour.<br /><br />
                Serait-il possible de nous contacter au plus vite ?<br /><br />
                Cordialement,<br />
                Titus</p>
                `
            },
            {
                id: 4,
                title: "Re: Informations production \"Into The Dark\"",
                emeteur: "titusinteractive@aol.com",
                text: `<p>Bonjour,<br /><br />
                Plusieurs revendeurs nous ont fait part de plaintes de leurs clients concernant le jeu.<br /><br />
                Merci de nous joindre au plus vite,<br />
                Titus</p>
                `
            },
            {
                id: 5,
                title: "Re: Informations production \"Into The Dark\"",
                emeteur: "titusinteractive@aol.com",
                text: `<p>Bonjour,<br /><br />
                Nous avons été convoqués dans le cadre d'une enquête sur la disparition de plusieurs joueurs.<br /><br />
                En l'absence de réponses de votre part, nous avons pris la décision de temporairement retirer le jeu de la vente.<br /><br />
                Titus</p>
                `
            }
        ]
    }
    
    init() {
        this.setDatas()
        this.initDOM()
        this.initLeftColumn()
        // this.openMailBox()
        this.closeMailBox()
    }

    initDOM() {
        this.leftColumnMailBox = document.getElementById('leftPartMainMailBox')
        this.rightColumnMailBox = document.getElementById('rightPartMainMailBox')
    }

    openMailBox() {
        var s = s_gi('wmg,wmgfr');					
        s_dtm.linkTrackVars='prop1,eVar4';					
        s_dtm.prop1='menace Santana';					
        s_dtm.eVar4 = 'menace Santana';					
        s_dtm.tl(this,'o','menace Santana:Into The Dark 3D NFT Game:Landing:Open Mail Click');					

        this.fullContent = document.getElementById('fullContent')
        this.interactiveContent = document.getElementById('interactiveContent')
        this.fullContent.style.display = "initial"
        this.interactiveContent.style.display = "initial"
        this.experience.world.clickRaycaster.openMailPart()
    }

    closeMailBox() {
        document.getElementById('mailBoxContent').style.display = "none"
    }

    initLeftColumn() {
        this.leftColumnMailBox.innerHTML = ""
        this.datas.forEach((mail) => {
            this.leftColumnMailBox.innerHTML += `
                <div class="mailPreview" id="mail-${mail.id}" data-id="${mail.id}">
                    <h3>${mail.title}</h3>
                    <p class="auteur"><span>de:</span>${mail.emeteur}</p>
                    <p class="content">${mail.text}</p>
                </div>   
            `
        })

        // document.querySelectorAll('.mailPreview').forEach((mail) => {
        //     mail.addEventListener('click', (e) => {
        //         this.setRightColumn(mail.getAttribute('data-id'))
        //     })
        // })
    }

    setRightColumn(id) {
        const mail = this.datas.find((mail) => mail.id == id)
        this.rightColumnMailBox.innerHTML = `
            <div class="mailFull">
                <h3>${mail.title}</h3>
                <p class="auteur"><span>de:</span>${mail.emeteur}</p>
                <p class="content">${mail.text}</p>
            </div>   
        `
    }
}