import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap'
import { Vector2 } from 'three'

const PI2 = Math.PI * 2

export default class Item {
    constructor(itemName, position, rotation, scale, colorMap, roughnessMap, normalMap, onClick, unlockAction, isMeshBasicMaterial, directOnclick) {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.itemName = itemName
        this.position = position
        this.rotation = rotation
        this.scale = scale

        this.isMeshBasicMaterial = isMeshBasicMaterial

        this.colorMap = colorMap
        this.roughnessMap = roughnessMap
        this.normalMap = normalMap

        this.onClick = onClick
        this.directOnclick = directOnclick

        this.unlockAction = unlockAction

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder(this.itemName)
        }

        // Resource
        this.resource = this.resources.items[this.itemName]

        this.setTextures()
        this.setMaterial()
        this.setModel()
        
        if (this.resource.animations[0]) {
            this.setAnimation()
        }
    }

    setTextures() {
        this.textures = {}
        this.textures.color = this.resources.items[this.colorMap] || null
        if (this.textures.color)
            this.textures.color.encoding = THREE.sRGBEncoding


        this.textures.roughness = this.resources.items[this.roughnessMap] || null

        this.textures.normal = this.resources.items[this.normalMap] || null

        // this.textures.AO = this.resources.items.CaveRockAO
        // this.textures.color.repeat.set(3, 3)
        // this.textures.color.wrapS = THREE.RepeatWrapping
        // this.textures.color.wrapT = THREE.RepeatWrapping
    }

    
    setAnimation()
    {
        this.animation = {}
        
        // Mixer
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        
        // Actions
        this.animation.actions = {}
        
        this.animation.actions.anim = this.animation.mixer.clipAction(this.resource.animations[0])
        
        this.animation.actions.current = this.animation.actions.anim
        
        this.animation.actions.anim.setLoop(THREE.LoopOnce);
        this.animation.actions.anim.clampWhenFinished = true;

        // Play the action
        this.animation.play = (name) =>
        {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 0)

            this.animation.actions.current = newAction
        }

        // Debug
        if(this.debug.active)
        {
            const debugObject = {
                playIdle: () => { this.animation.play('anim') }
            }
            this.debugFolder.add(debugObject, 'playIdle')
        }
    }

    setMaterial() {
        if (this.textures.color)
            this.textures.color.flipY = false
        if (this.textures.roughness)
            this.textures.roughness.flipY = false
        if (this.textures.normal)
            this.textures.normal.flipY = false
        // this.textures.AO.flipY = false

        if (this.isMeshBasicMaterial) {
            this.material = new THREE.MeshBasicMaterial({
                map: this.textures.color ? this.textures.color : null
            })
            
            this.material.color.r = 0.6
            this.material.color.g = 0.6
            this.material.color.b = 0.6
        } else {
            this.material = new THREE.MeshStandardMaterial({
                map: this.textures.color ? this.textures.color : null,
                roughnessMap: this.textures.roughness ? this.textures.roughness : null,
                roughness: this.textures.roughness ? 0.4 : .8,
                normalMap: this.textures.normal ? this.textures.normal : null,
                normalScale: this.textures.normal ? new THREE.Vector2(1, 1) : new THREE.Vector2(0, 0),
                envMap: this.experience.world.environment.environmentMap
            })
        }

        if (this.debug.active && !this.isMeshBasicMaterial) {
            this.debugFolder.add(this.material, 'metalness').min(-1).max(5).step(0.0001)
            this.debugFolder.add(this.material, 'roughness').min(-1).max(5).step(0.0001)
            this.debugFolder.add(this.material.normalScale, 'x').min(-1).max(1).step(0.0001)
            this.debugFolder.add(this.material.normalScale, 'y').min(-1).max(1).step(0.0001)
            this.debugFolder.add(this.material, 'aoMapIntensity').min(0).max(1).step(0.0001)
        }
    }

    setModel() {
        this.model = this.resource.scene.clone()
        this.model.scale.set(this.scale.x, this.scale.y, this.scale.z)
        this.model.position.set(this.position.x, this.position.y, this.position.z)
        this.model.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)

        this.model.name = "Item_" + this.itemName
        this.scene.add(this.model)

        if (this.textures.color || this.textures.roughness || this.textures.normal) {
            this.model.material = this.material
        }
        this.model.receiveShadow = false
        this.model.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                // child.castShadow = true
                child.receiveShadow = false
                if (this.textures.color || this.textures.roughness || this.textures.normal)
                    child.material = this.material
                // child.material.side = THREE.DoubleSide
                // child.name = "Item_" + this.itemName + "-child" 

                // this.experience.world.itemsGeometry = _mergeMeshes([child, this.experience.world.itemsGeometry], true)
                // this.experience.collidableMeshList.push(child)
            }
        })

        if (this.debug.active) {
            this.debugFolder.add(this.model.scale, 'x').min(0).max(5)
            this.debugFolder.add(this.model.scale, 'y').min(0).max(5)
            this.debugFolder.add(this.model.scale, 'z').min(0).max(5)
            this.debugFolder.add(this.model.position, 'x').min(-20).max(20)
            this.debugFolder.add(this.model.position, 'y').min(-3).max(3)
            this.debugFolder.add(this.model.position, 'z').min(-0).max(50)
            this.debugFolder.add(this.model.rotation, 'x').min(-Math.PI).max(Math.PI)
            this.debugFolder.add(this.model.rotation, 'y').min(-Math.PI).max(Math.PI)
            this.debugFolder.add(this.model.rotation, 'z').min(-Math.PI).max(Math.PI)
        }


        
        // this.model.matrixAutoUpdate = false
        // this.experience.world.environment.environmentMap.updateMaterials()
    }

    getInfos() {
        console.log(`
            { x: ${this.model.position.x}, y: ${this.model.position.y}, z: ${this.model.position.z} }, { x: ${this.model.rotation.x}, y: ${this.model.rotation.y}, z: ${this.model.rotation.z} }, { x: ${this.model.scale.x}, y: ${this.model.scale.y}, z: ${this.model.scale.z} }
        `)
    }

    removeDOM() {
        document.querySelector('.' + this.itemDOMRef).remove();
    }

    initDOM(x, y, z, fpx, fpz, fry, name) {
        this.doorPointsContainer = document.getElementById('doorPoints')
        

        this.doorPointDiv = document.createElement("div");
        this.doorPointDiv.setAttribute("class", `point point-${name} ${this.name}`);
        this.itemDOMRef = `point-${name}`
        const doorLabel = document.createElement("div");
        doorLabel.setAttribute("class", `label`);

        this.doorPointDiv.appendChild(doorLabel)

        this.doorPointsContainer.appendChild(this.doorPointDiv);

        doorLabel.addEventListener('click', () => {
            if (this.directOnclick) {
                this.directOnclick()
            }

            if (this.experience.world.character.hasCameraMovement === true) {
                this.experience.world.character.defaultCameraOffset.x = this.experience.world.character.defaultCameraXOffsetOnAction
            }
            
            this.experience.world.character.isLockedUserActions = true
            this.experience.world.character.animation.play('walking')

            let finalCharacterPosition = new THREE.Vector3(fpx, 0, fpz)
            let finalCharacterRotation = new THREE.Vector3()
            finalCharacterRotation.y = fry

            // Remove DOM Point search
            this.experience.points = this.experience.points.filter((el) => el.element !== this.doorPointDiv)

            // Determine the angle
            let differencePosition = new THREE.Vector3().subVectors(finalCharacterPosition,  this.experience.world.character.model.position )
            let InitialRotationY = this.experience.world.character.model.rotation.y
            let aCosAngleCharacter =  Math.atan(
                differencePosition.x / differencePosition.z
            )

            // Rotate character to the item
            if (differencePosition.z >= 0) {
                if (differencePosition.x < 0) {
                    aCosAngleCharacter += Math.PI
                } else {
                    aCosAngleCharacter += Math.PI
                }
            }
    
            aCosAngleCharacter += PI2 * Math.floor(InitialRotationY/PI2)
            if (aCosAngleCharacter - InitialRotationY < -Math.PI) {
                aCosAngleCharacter += PI2
            } else if (aCosAngleCharacter - InitialRotationY > Math.PI) {
                aCosAngleCharacter -= PI2
            }

            // Maker the character look at the object
            gsap.to(
                this.experience.world.character.model.rotation, {
                    y: aCosAngleCharacter,
                    duration: 0.4,
                    ease: 'linear'
                }
            )  

            // Move the character to the item
            this.experience.world.character.playSound()
            gsap.to(this.experience.world.character.model.position, {
                x: finalCharacterPosition.x,
                z: finalCharacterPosition.z,
                duration: this.experience.world.character.model.position.distanceTo(x ? {
                    x,
                    y: -0.3,
                    z
                }: this.model.position) / 3,
                ease: 'linear',
                onComplete: () => {
                    this.experience.world.character.removeSound()
                    if (finalCharacterRotation.y > this.experience.world.character.model.rotation.z) {
                        this.experience.world.character.animation.play('turnLeft')
                    } else {
                        this.experience.world.character.animation.play('turnRight')
                    }

                    let finalRotation = finalCharacterRotation.y
                    let characterRotationI = this.experience.world.character.model.rotation.y

                    finalRotation += PI2 * Math.floor(
                        characterRotationI / PI2
                    )

                    if (finalRotation - characterRotationI < -Math.PI) {
                        finalRotation += PI2
                    } else if (finalRotation - characterRotationI > Math.PI) {
                        finalRotation -= PI2
                    }

                    gsap.to(this.experience.world.character.model.rotation, {
                        duration: 1,
                        y: finalRotation,
                        ease: 'easeOut',
                        onComplete: () => {
                            this.experience.world.character.animation.play('pickObject')

                            setTimeout(() => {
                                this.onClick()
                                this.removeDOM()
                                this.experience.world.character.defaultCameraOffset.x = 0
                                if (this.unlockAction)
                                    this.experience.world.character.isLockedUserActions = false
                            }, 1000)
                        }
                    })
                }
            })
            
            this.doorPointDiv.classList.remove('visible')
        })

        this.experience.points.push({
            position: new THREE.Vector3(x ? x : this.model.position.x, y ? y : this.model.position.y, z ? z : this.model.position.z),
            element: this.doorPointDiv
        })
    }

    openLight(id) {
        this.experience.world[id].intensity = 5
    }
    closeLight(id) {
        this.experience.world[id].intensity = 0
    }


    update() {
        this.animation.mixer.update(this.time.delta * 0.001)
    }
}