import * as THREE from 'three'
import Experience from '../Experience.js'

import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js';
import { VertexTangentsHelper } from 'three/examples/jsm/helpers/VertexTangentsHelper.js';

export default class Cave
{
    constructor(position, rotation, design)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.position = position
        this.rotation = rotation
        this.design = design

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('cave')
        }

        this.setTextures()
        this.setMaterial()
        this.setModel()
    }

    setTextures() {
        this.textures = {}

        this.textures.floor = {}
        this.textures.floor.color = this.resources.items.ConcreteFloorColor
        this.textures.floor.color.encoding = THREE.sRGBEncoding
        this.textures.floor.color.wrapS = THREE.RepeatWrapping
        this.textures.floor.color.wrapT = THREE.RepeatWrapping      
        this.textures.floor.color.repeat.set(40, 40) 

        
        this.textures.floor.roughness = this.resources.items.ConcreteFloorColor
        this.textures.floor.roughness.encoding = THREE.sRGBEncoding
        this.textures.floor.roughness.wrapS = THREE.RepeatWrapping
        this.textures.floor.roughness.wrapT = THREE.RepeatWrapping      
        this.textures.floor.roughness.repeat.set(40, 40) 

        
        this.textures.floor.normal = this.resources.items.ConcreteFloorColor
        this.textures.floor.normal.encoding = THREE.sRGBEncoding
        this.textures.floor.normal.wrapS = THREE.RepeatWrapping
        this.textures.floor.normal.wrapT = THREE.RepeatWrapping      
        this.textures.floor.normal.repeat.set(40, 40) 

        this.textures.wall = {}

        this.textures.wall.lightmap = this.resources.items.WallsLightmap
        this.textures.wall.lightmap.encoding = THREE.sRGBEncoding
        // this.textures.wall.lightmap.magFilter = THREE.NearestFilter
        this.textures.wall.lightmap.flipY = false
    }

    setMaterial() {
        this.wallMaterial = new THREE.MeshStandardMaterial({
            side: THREE.DoubleSide,
            lightMap: this.textures.wall.lightmap,
            map: this.textures.floor.color,
            lightMapIntensity: 20,
        })

        this.toitMaterial = new THREE.MeshStandardMaterial({
            map: this.textures.floor.color,
            lightMap: this.textures.wall.lightmap,
            lightMapIntensity: 20
        })

        this.params = {}
        this.params.NearestFilter = false
    }

    setModel()
    {
       
        this.model2 = this.resources.items.WallsModelLight.scene.clone()
        this.experience.renderer.instance.compile(this.model2, this.experience.camera.instance)

        // this.model2.scale.set(1.6, 1.6, 1.6)
        if (this.design === 0) {
            this.model2.scale.set(1.8, 1.8, 1.8)
        } else if (this.design === 1) {
            this.model2.scale.set(1.5, 1.7, 1.5)
        }
        
        this.model2.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
        this.model2.position.set(this.position.x, this.position.y, this.position.z)

        this.model2.name = "Cave"
        this.scene.add(this.model2)

        this.addCollide()

        if (this.debug.active) {
            this.debug.ui.add(this.model2.position, 'x').min(-30).max(30)
            this.debug.ui.add(this.model2.position, 'y').min(-5).max(5)
            this.debug.ui.add(this.model2.position, 'z').min(-30).max(30)
        }

        this.model2.receiveShadow = false
        this.model2.castShadow = false
        this.model2.frustumCalled = false

        this.model2.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = false
                child.receiveShadow = false
                child.frustumCalled = false

                if (child.name === "Plafond") {
                    child.material = this.toitMaterial
                } else if (child.name === "Murs") {
                    child.material = this.wallMaterial
                } else if (child.name === "Poteaux") {
                    child.material = this.wallMaterial
                    // child.material.color.r = 0.8
                    // child.material.color.g = 0.8
                    // child.material.color.b = 0.8
                }
            }
        })
    }

    destroy()
    {
        // this.sizes.off('resize')
        // this.time.off('tick')
        
        const cleanMaterial = material => {
            console.log('dispose material!')
            material.dispose()
        
            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object') {
                    console.log('dispose texture!')
                    value.dispose()
                }
            }
        }

        // Traverse the whole scene
        this.model2.scene.traverse(object => {
            if (!object.isMesh) return
            
            console.log('dispose geometry!' + object.geometry.name)
            object.geometry.dispose()
            this.scene.remove(object.geometry)
        
            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })

        // this.camera.controls.dispose()
        // this.renderer.instance.dispose()

        if(this.debug.active)
            this.debug.ui.destroy()
    }

    addCollide() {
        this.experience.world.character.canCollideList.push(this.model2)
    }
}