import * as THREE from 'three'
import Experience from './Experience.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { LuminosityShader } from 'three/examples/jsm/shaders/LuminosityShader.js';
import gsap from 'gsap'


export default class Renderer
{
    constructor()
    {
        this.experience = new Experience()
        this.debug = this.experience.debug.ui
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera

        this.setInstance()
        this.setPostProcessing()

        if (this.experience.debug.active) { 
            this.initDebug()
        }
    }

    setInstance()
    {
        this.effectController = {
            focus: 20.0,
            aperture: 10,
            maxblur: 100,  
            
            bloom: {
                exposure: .55,
                bloomStrength: .6,
                bloomThreshold: 0.5,
                bloomRadius: 0
            }
        };
        
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: false,
            powerPreference: 'high-performance'
        })
        this.instance.physicallyCorrectLights = true
        this.instance.gammaFactor = 2.2;
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.ReinhardToneMapping
        this.instance.toneMappingExposure = Math.pow(this.effectController.bloom.exposure, 4.0)
        this.instance.shadowMap.autoUpdate = false
        this.instance.shadowMap.enabled = false
        // this.instance.shadowMap.enabled = true
        this.instance.setClearColor('#211d20')
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 1))
    }

    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 1))
    }

    initComposer() {
        this.postprocessing.composer = new EffectComposer(this.instance)
        this.postprocessing.composer.setSize(this.sizes.width, this.sizes.height)
        this.postprocessing.composer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
    }

    setBloomPass() {
        this.postprocessing.bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 4.5, 0.4, 0.85 );
        this.postprocessing.bloomPass.threshold = this.effectController.bloom.bloomThreshold;
        this.postprocessing.bloomPass.strength = this.effectController.bloom.bloomStrength;
        this.postprocessing.bloomPass.radius = this.effectController.bloom.bloomRadius;
    }

    addBloomPass() {
        this.postprocessing.composer.addPass( this.postprocessing.bloomPass );
    }

    setBokehPass() {
        this.postprocessing.bokehPass = new BokehPass(this.scene, this.camera.instance, {
            focus: 1.0,
            aperture: 0,
            maxblur: 0,

            width: this.sizes.width,
            height: this.sizes.height
        });
    }

    addBokehPass() {
        this.postprocessing.composer.addPass(this.postprocessing.bokehPass)
    }

    setGlitchPass() {
        this.postprocessing.glitchPass = new GlitchPass()
    }

    addGlitchPass() {
        this.postprocessing.composer.addPass(this.postprocessing.glitchPass)
    }

    removeGlitchPass() {
        this.postprocessing.composer.removePass(this.postprocessing.glitchPass)
    }

    setLuminosityPass() {
        this.postprocessing.luminosityPass = new ShaderPass( LuminosityShader );
    }

    addLuminosityPass() {
        this.postprocessing.composer.addPass(this.postprocessing.luminosityPass)
    }

    setGammaCorrectionpass() {
        this.postprocessing.gammaCorrectionPass = new ShaderPass(GammaCorrectionShader)
    }

    addGammaCorrectionPass() {
        this.postprocessing.composer.addPass(this.postprocessing.gammaCorrectionPass);
    }

    setRenderPass() {
        this.postprocessing.renderScene = new RenderPass( this.scene, this.camera.instance );
    }

    addRenderPass() {
        this.postprocessing.composer.addPass( this.postprocessing.renderScene );
    }

    setPostProcessing() {
        this.postprocessing = {}

        this.initComposer()

        this.setRenderPass()
        // this.setBloomPass()
        // this.setGammaCorrectionpass()
        this.setGlitchPass()
        // this.setLuminosityPass()
        // this.setBokehPass()

        this.addRenderPass()
        // this.addBloomPass()
        // this.addGammaCorrectionPass()
        // this.addBokehPass()
        // this.addGlitchPass()
        // this.addLuminosityPass()
    }

    initDebug() {
        this.debug.add(this.effectController.bloom, 'exposure', 0.1, 2).onChange((value) => {
            this.instance.toneMappingExposure = Math.pow(value, 4.0);
        });

        this.debug.add( this.effectController, 'focus', 0.0, 500.0, 10 ).onChange( () => this.matChanger() );
        this.debug.add( this.effectController, 'aperture', 0, 10, 0.1 ).onChange( () => this.matChanger() );
        this.debug.add( this.effectController, 'maxblur', 0.0, 10, 0.001 ).onChange( () => this.matChanger() );
        
        
        this.debug.close();
    }

    update()
    {
        // this.instance.render(this.scene, this.camera.instance)
        this.postprocessing.composer.render();

        // console.log("Scene polycount:", this.instance.info.render.triangles)
        // console.log("Active Drawcalls:", this.instance.info.render.calls)
        // console.log("Textures in Memory", this.instance.info.memory.textures)
        // console.log("Geometries in Memory", this.instance.info.memory.geometries)
    }

    destroy() {
        this.renderer.instance.dispose()
    }
}