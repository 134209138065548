import * as THREE from 'three'
import Experience from '../Experience'

export default class ClickRaycaster {
    constructor() {
        this.experience = new Experience()

        this.interactiveDOM = document.getElementById('interactiveContent')
        this.interactiveDOMBackdrop = document.getElementById('interactiveContentBackdrop')
        this.interactiveDOMImageContainer = document.getElementById('interactiveContentImageContainerImage')
        this.interactiveDOMClose = document.getElementById('fakeWindowsClose')
        this.mailBoxContentDOM = document.getElementById('mailBoxContent')
        this.hackScreenDOM = document.getElementById('hackScreen')

        this.init()
    }

    init() {
        this.raycaster = new THREE.Raycaster()
        this.mouse = new THREE.Vector2()

        this.initEvents()
        this.closeInteractiveDOM()
    }

    initEvents() {
        this.interactiveDOMBackdrop.addEventListener('click', () => {
            this.closeInteractiveDOM()
        })

        this.interactiveDOMClose.addEventListener('click', () => {
            this.closeInteractiveDOM()
        })

        window.addEventListener('click', (event) =>
        {
            this.mouse.x = event.clientX / this.experience.sizes.width * 2 - 1
            this.mouse.y = - (event.clientY / this.experience.sizes.height) * 2 + 1
            
            this.onClick()
        })
    }

    closeInteractiveDOM() {
        this.interactiveDOM.style.display = "none"
        this.interactiveDOMImageContainer.style.display = "none"
        this.mailBoxContentDOM.style.display = "none"
        this.hackScreenDOM.style.display = "none"
    }

    openImagePart() {
        this.interactiveDOMImageContainer.style.display = "flex"
    }

    openMailPart() {
        this.mailBoxContentDOM.style.display = "initial"
    }

    openInteractiveDOM() {
        this.interactiveDOM.style.display = "initial"
    }

    openHackScreen() {
        this.hackScreenDOM.style.display = "initial"
    }

    onClick() {
        this.raycaster.setFromCamera(this.mouse, this.experience.camera.instance)

        this.intersects = this.raycaster.intersectObjects(this.experience.interactiveElements)

        if (this.intersects.length > 0) {
            this.intersects[0].object.customData()
            
        }
    }

    showImage(image) {
        this.openImagePart()
        this.interactiveDOMImageContainer.innerHTML = `<img src="${image}" alt="" class="fullScreenImg" />`
    }
}