import * as THREE from 'three'
import Experience from '../Experience.js'
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import Raycaster from '../Raycaster'
import { chdir } from 'process';
import gsap from "gsap"

// Add the extension functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

THREE.Mesh.prototype.raycast = acceleratedRaycast;

export default class Character
{
    constructor(userControl)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.resource = this.resources.items.menaceModel
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.isFirstRender = true
        this.isPlayingFootstep = false

        this.isLockedUserActions = false
        this.hasCameraMovement = false
        this.cameraFollowUser = true

        this.getBoundingBox = true
        this.canCollideList = []

        this.mouse = { x: 0, y: 0 }
        this.lastFrameWasGlitched = false

        // ANIMATIONS
        this.userControl = userControl || false

        this.directions = {
            left: false,
            right: false,
            up: false,
            boost: false
        }

        // this.currentLookat = new THREE.Vector3()
        this.currentPosition = new THREE.Vector3()

        this.params = {
            speed: 0.0024,
        }

        this.defaultCameraOffset = new THREE.Vector3(0, 4, 3.3)
        this.defaultCameraXOffsetOnAction = 4

        this.crossFadeControls = [];

        this.currentBaseAction = 'gettingUp';
        this.allActions = [];

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Character')
            this.setDebug()
        }

        if (this.userControl) {
            this.setUserControl()
        }

        this.init()
    }

    init() {
        this.setModel()
        this.setAnimations()
        this.initThirdCamera()
        // this.getBoundingBox && this.initBoundingBox()

        if (this.experience.isMobile) {
            this.initMobileControls()
        } else {
            this.initMouseMove()
        }
    }

    initMouseMove() {
        document.querySelector('canvas').addEventListener('mousemove', (e) => {
            this.mouse.x = e.clientX/this.experience.sizes.width
            this.mouse.y = e.clientY/this.experience.sizes.height
        })
    }

    initBoundingBox() {
        if (this.debug.active) {
            this.debugFolder.add(this.hitBoxMaterial, "visible").name("Character Hitbox Visibility")
            this.debugFolder.add(this.params, "height raycaster visibility").name("Height Raycaster Visibility")
        }
    }

    updateBoundingBox() {
        const raycaster = new THREE.Raycaster();
        let rayOrigin = new THREE.Vector3()
        rayOrigin.copy(this.model.position)
        rayOrigin.y = this.model.position.y + 1
        let rayDirection = new THREE.Vector3()
        this.model.getWorldDirection(rayDirection)

        rayDirection.normalize()
        
        rayDirection.x *= -1
        rayDirection.z *= -1
        
        raycaster.set(rayOrigin, rayDirection)
        raycaster.firstHitOnly = true;

        // this.scene.remove(this.arrow) ;
        this.intersects = raycaster.intersectObjects( this.canCollideList );
    }

    setDebug() {
    }

    setUserControl() {
        window.addEventListener('keydown', (e) => this.setUserDirection(e))
        window.addEventListener('keyup', (e) => this.unsetUserDirection(e))
    }

    showModel(visibility) {
        this.model.visible = visibility;
    }

    showSkeleton(visibility) {
        this.skeleton.visible = visibility;
    }

    setUserDirection(e) {
        if (this.isLockedUserActions === false) {
            if (e.key.toLowerCase() === "z") {
                this.directions.up = true
                this.playSound()
            }
            
            if (e.key.toLowerCase() === "arrowup") {
                this.directions.up = true
                this.playSound()
            }
            
            if (e.key.toLowerCase() === "arrowleft")
                this.directions.left = true
            
            if (e.key.toLowerCase() === "arrowright")
                this.directions.right = true

            if (e.key.toLowerCase() === "q")
                this.directions.left = true
            
            if (e.key.toLowerCase() === "d")
                this.directions.right = true
            
            if (e.key.toLowerCase() === "shift") {
                if (this.experience.debug.active) {
                    this.directions.boost = true
                }
            }

            this.playAnim()
        } 
    }

    unsetUserDirection(e) {
        if (this.isLockedUserActions === false) {
            if (e.key.toLowerCase() === "z") {
                this.directions.up = false
                this.removeSound()
            }
            
            if (e.key.toLowerCase() === "q")
                this.directions.left = false
            
            if (e.key.toLowerCase() === "d")
                this.directions.right = false
            
            if (e.key.toLowerCase() === "shift")
                this.directions.boost = false
            
            if (e.key.toLowerCase() === "arrowup") {
                this.directions.up = false
                this.removeSound()
            }
            
        
            if (e.key.toLowerCase() === "arrowleft")
                this.directions.left = false
        
            if (e.key.toLowerCase() === "arrowright")
                this.directions.right = false
    
            if (this.directions.up === false && this.directions.isLockedUserActions === false)  
                this.animation.play('idle')
            
            this.playAnim()
        }
    }

    playAnim() {
        if (this.directions.up === true) {  
            if (this.directions.boost || this.directions.upRatio > 50 ) {
                this.animation.play('running')
            } else {
                this.animation.play('walking')
            }
        } else if (!this.directions.up) {
            if (this.directions.left === true && this.directions.right === false) {
                this.animation.play('turnRight')
            } else if (this.directions.left === false && this.directions.right === true) {
                this.animation.play('turnLeft')
            } else {
                this.animation.play('idle')
            }
        }
    }

    setModel() {
        this.animations = this.resource.animations
        this.model = this.resource.scene
        this.model.name = "Character"
        this.model.position.y = 2.989
        this.model.visible = true
        this.model.position.set(-12.6, -2.989999999999999, 24.05)
        this.model.rotation.set(0, -Math.PI/6, 0)
        this.model.scale.set(1, 1, 1)
        this.scene.add(this.model)

        this.textures = {}
        this.textures.color = this.resources.items.menaceModelImage
        this.textures.color.flipY = false

        this.material = new THREE.MeshStandardMaterial({
            map: this.textures.color
        })
        
        this.model.children[0].rotation.z = Math.PI
        this.model.children[0].scale.set(2.1, 2, 2)
    
        this.model.frustumCalled = false

        this.model.traverse((child) =>
        {
            this.model.frustumCalled = false
            if(child instanceof THREE.Mesh)
            {
                child.receiveShadow = false
                child.castShadow = false
                child.name = "character"
                child.material = this.material
            }
        })
    }

    setAnimations() {
        this.animation = {}
        
        // Mixer
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        
        // Actions
        this.animation.actions = {}
        
        this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[2])
        this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[1])
        this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
        this.animation.actions.turnLeft = this.animation.mixer.clipAction(this.resource.animations[5])
        this.animation.actions.turnRight = this.animation.mixer.clipAction(this.resource.animations[4])
        this.animation.actions.doorOpen = this.animation.mixer.clipAction(this.resource.animations[1])
        this.animation.actions.explosion = this.animation.mixer.clipAction(this.resource.animations[1])
        this.animation.actions.gettingUp = this.animation.mixer.clipAction(this.resource.animations[0])
        this.animation.actions.pickObject = this.animation.mixer.clipAction(this.resource.animations[3])        
        this.animation.actions.walking.setLoop(THREE.LoopRepeat)

        // this.animation.actions.doorOpen.setLoop( THREE.LoopOnce );
        // this.animation.actions.doorOpen.clampWhenFinished = true;

        // // this.animation.actions.gettingUp.setLoop( THREE.LoopOnce );
        // // this.animation.actions.gettingUp.clampWhenFinished = true;

        this.animation.actions.gettingUp.setLoop( THREE.LoopOnce );
        this.animation.actions.gettingUp.clampWhenFinished = true;

        this.animation.actions.pickObject.setLoop( THREE.LoopOnce );
        this.animation.actions.pickObject.clampWhenFinished = true;


        this.animation.actions.current = this.animation.actions.idle
        this.animation.actions.current.play()

        this.animation.mixer.addEventListener( 'finished', (e) => {

            if (this.animation.actions.current === this.animation.actions.gettingUp) {
                this.animation.play('idle')
            }

            if (this.animation.actions.current === this.animation.actions.pickObject) {
                this.animation.play('idle')
            }

            if (this.animation.actions.current === this.animation.actions.walk) {
                this.animation.play('walk')
            }
        } )

        // Play the action
        this.animation.play = (name) =>
        {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            if (newAction !== oldAction) {
                newAction.reset()
                newAction.play()
                newAction.crossFadeFrom(oldAction, 0.4)

                this.animation.actions.current = newAction
            }
        }
    }

    initThirdCamera() {
        this.cameraAttached = this.experience.camera
    }

    calculateIdealLookat() {
        const idealLookAt = new THREE.Vector3(0, 2.2, 0)
        const RotationQuaternion = new THREE.Quaternion()
        RotationQuaternion.setFromAxisAngle(this.model.rotation, 0);
        idealLookAt.applyQuaternion(RotationQuaternion)
        idealLookAt.add(this.model.position)
        return idealLookAt
    }

    updateCamera() {
        const idealLookat = this.calculateIdealLookat()
        
        const relativeCameraOffset = this.defaultCameraOffset.clone()

        relativeCameraOffset.x -= this.experience.isMobile ? 0 : (this.mouse.x - 0.5) * 4 
        // relativeCameraOffset.y -= (this.mouse.y - 0.5) * 4 
            
        const cameraOffset = relativeCameraOffset.applyMatrix4( this.model.matrixWorld );  
        this.experience.camera.instance.position.lerp(cameraOffset, .06);
        this.preventCameraClip()
        // this.currentLookat.copy(idealLookat)
        if (this.cameraFollowUser)
            this.experience.camera.instance.lookAt(idealLookat)
    }

    preventCameraClip() {
        const cameraPosition = this.experience.camera.instance.position
        
        const cameraRaycaster = new THREE.Raycaster();
        let rayOrigin = cameraPosition
        let rayDirection = new THREE.Vector3()

        rayDirection.subVectors(new THREE.Vector3(
            this.model.position.x,
            this.model.position.y,
            this.model.position.z
        ), cameraPosition)
        
        rayDirection.normalize()

        cameraRaycaster.set(rayOrigin, rayDirection)
        cameraRaycaster.firstHitOnly = false;

        const cameraClipIntersects = cameraRaycaster.intersectObjects( this.canCollideList );     
        const distanceCameraModel = cameraPosition.distanceTo(this.model.position)

        if (cameraClipIntersects.length > 0) {
            let AAA = cameraClipIntersects.filter((el) => el.distance < distanceCameraModel)
            if (AAA[AAA.length - 1]) {
                let direction = new THREE.Vector3();
                direction.subVectors( AAA[AAA.length - 1].point, this.model.position ).normalize(); ;

                
                this.experience.camera.instance.position.set(
                    AAA[AAA.length - 1].point.x,
                    AAA[AAA.length - 1].point.y + 0.1,
                    AAA[AAA.length - 1].point.z
                )

                this.lastFrameWasGlitched = true
            } else {
                this.lastFrameWasGlitched = false
            }
        }
    }

    playSoundFoot() {
        this.experience.sounds.play('FootStep', 0.02)
    }
    playSound() {
        if (this.isPlayingFootstep === false) {
            this.footstep = setInterval(() => this.playSoundFoot(), 500)
            this.isPlayingFootstep = true
        }
    }

    removeSound() {
        clearInterval(this.footstep);
        this.isPlayingFootstep = false
    }

    lockUserActions() {
        this.isLockedUserActions = true
        this.directions.up = false
        this.directions.left = false
        this.directions.right = false
    }

    createCinematique(x, z, duration, watchDuration, target, comparativeX, comparativeY, fov) {
        this.lockUserActions()
        this.cameraFollowUser = false
        gsap.to(this.experience.camera.instance.position, {
            x,
            z,
            duration,
            onComplete: () => {
                gsap.to(this.experience.camera.instance.position, {
                    x,
                    duration: watchDuration,
                    onComplete: () => {
                        this.cameraFollowUser = true
                        this.isLockedUserActions = false
                    }
                })

                gsap.to(this.experience.camera.instance, {
                    fov: fov,
                    duration: watchDuration,
                    onUpdate: () => {
                        this.experience.camera.instance.updateProjectionMatrix();
                    },
                    onComplete: () => {
                        this.experience.camera.instance.fov = 65
                        this.experience.camera.instance.updateProjectionMatrix()
                    }
                })

                gsap.to(this.experience.camera.instance.position, {
                    duration: watchDuration,
                    x: x + comparativeX,
                    z: z + comparativeY
                })
            },
            onUpdate: () => {
                this.experience.camera.instance.lookAt(target.position)
            }
        })
    }

    update()
    {
        if (this.isFirstRender) {
            this.isFirstRender = false
            this.experience.world.addScene1()
        }

        if (this.model && this.cameraFollowUser)
            this.updateCamera()

        if (this.getBoundingBox) {
            this.updateBoundingBox()
        }
        
        
        this.modelDirection = new THREE.Vector3( 0, 0, -1 ).applyQuaternion( this.model.quaternion );
        this.finalSpeed = this.params.speed * (this.directions.boost ? 4.2 : this.directions.up ? 1.2 : 0)
        
        if (this.intersects && this.intersects[0]) {
            if (this.intersects[0].distance < 1.5) {
                this.finalSpeed = 0
                this.directions.upRatio = 0
            }
        }

        if (this.directions.up) {
            if (this.experience.isMobile) {
                this.model.position.z += this.directions.upRatio * this.time.delta * this.modelDirection.z * 0.00004
                this.model.position.x += this.directions.upRatio * this.time.delta * this.modelDirection.x * 0.00004
            } else {
                this.model.position.z += this.finalSpeed * this.time.delta * this.modelDirection.z
                this.model.position.x += this.finalSpeed * this.time.delta * this.modelDirection.x
            }
        }
        
        if (this.directions.left) {
            if (this.experience.isMobile) {
                this.model.rotation.y += ((Math.PI/2) * 0.4 * this.directions.leftRatio * 0.0007)
            } else {
                this.model.rotation.y += ((Math.PI/2 * this.time.delta) * 0.0015)
            }
        } else if (this.directions.right) {
            if (this.experience.isMobile) {
                this.model.rotation.y -= ((Math.PI/2) * 0.4 * this.directions.rightRatio * 0.0007)
            } else {
                this.model.rotation.y -= ((Math.PI/2 * this.time.delta) * 0.0015)
            }
        }

        this.model.rotation.y %= 2 * Math.PI

        if (this.animation.mixer) {
            this.animation.mixer.update(this.time.delta * 0.00132)
        }
    }

    initMobileControls() {
        const outerDiv = document.createElement('div')
        outerDiv.setAttribute("id", "outer-mobile_control");
        const innerDiv = document.createElement('div')
        innerDiv.setAttribute("id", "inner-mobile_control");
        
        document.getElementById('fullContent').appendChild(outerDiv)

        this.outerMobileControlsDOM = document.getElementById('outer-mobile_control')
        this.outerMobileControlsDOM.appendChild(innerDiv)

        this.isPushedMobile = false
        this.innerMobileControlsDOM = document.getElementById('inner-mobile_control')

        this.canvas = document.querySelector('canvas')

        this.canvas.addEventListener('touchstart', (e) => this.mobileStart(e))
        // this.outerMobileControlsDOM.addEventListener('touchstart', (e) => this.mobileMove())

        this.canvas.addEventListener('touchend', e => {
            if (this.isLockedUserActions === false) {
                this.isPushedMobile = false
                this.startPushY = e.changedTouches[0].clientX
                this.directions.up = false
                this.directions.left = false
                this.directions.right = false

                this.animation.play('idle')

                this.directions.upRatio = 0

                // this.outerMobileControlsDOM.style.display = "none"

                this.innerMobileControlsDOM.style.left = "50%"
                this.innerMobileControlsDOM.style.top = "50%"
            }
        })

        this.canvas.addEventListener('touchmove', e => {
            if (this.isLockedUserActions === false) {
                this.mobileMove(e)
            this.playAnim()
            this.innerMobileControlsDOM.style.left = e.changedTouches[0].clientX - this.outerMobileControlsDOM.getBoundingClientRect().left - 15 + "px"
            this.innerMobileControlsDOM.style.top = Math.max(e.changedTouches[0].clientY - this.outerMobileControlsDOM.getBoundingClientRect().top - 15, -50) + "px"
            }
        })
    }

    mobileStart(e) {
        this.isPushedMobile = true
        this.outerMobileControlsDOM.style.display = "initial"
        this.startPushY = e.changedTouches[0].clientY
        this.startPushX = e.changedTouches[0].clientX
    }

    mobileMove(e) {
        this.diffMoveY = e.changedTouches[0].clientY
        this.diffMoveX = e.changedTouches[0].clientX
        
        if (this.startPushY - this.diffMoveY > 20) {
            this.directions.up = true
            this.directions.upRatio = Math.min(Math.abs((this.startPushY - this.diffMoveY) * 2), 100)
        } else if (this.startPushY - this.diffMoveY < 20) {
            this.directions.up = false
        }
        
        if (this.startPushX - this.diffMoveX > 0) {
            this.directions.left = true
            this.directions.right = false
            this.directions.leftRatio = Math.min(Math.max(0, Math.abs(this.startPushX - this.diffMoveX) - 30), 100)
        } else if (this.startPushX - this.diffMoveX < 0) {
            this.directions.right = true
            this.directions.left = false
            this.directions.rightRatio = Math.min(Math.max(0, Math.abs(this.startPushX - this.diffMoveX) - 30), 100)
        }
    }
}