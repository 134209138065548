import * as dat from "lil-gui";
import Stats from "stats.js";
import Experience from "../Experience";
import * as THREE from "three";

export default class Debug {
  constructor() {
    // this.active = window.location.hash === "#noNFT";
    this.active = false;
    // this.noNFT = window.location.hash === '#noNFT'
    this.experience = new Experience();

    if (this.active) {
      this.ui = new dat.GUI();
      this.stats = new Stats();
      this.stats.showPanel(2); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(this.stats.dom);

      this.actions = {
        xRadiusCameraMovement: 4,
        xCameraOffset: 0,
        yCameraOffset: 4,
        zCameraOffset: 3.3,
        cameraFOV: 65,
        GodMod: () => this.GodMod(),
        TPToNFTRoom: () => this.TPToNFTRoom(),
        RemoveCollisions: () => this.RemoveCollisions(),
        OpenAllDoors: () => this.OpenAllDoors(),
        RemoveFog: () => this.RemoveFog(),
        WatchFar: () => this.WatchFar(),
        WhiteBG: () => this.WhiteBG(),
        AugmentedView: () => this.AugmentedView(),
        createDevRoom: () => this.createDevRoom(),
        createNFTRoom: () => this.createNFTRoom(),
        createScreamerRoom: () => this.createScreamerRoom(),
        createRandomRoom1: () => this.createRandomRoom1(),
        createRandomRoom2: () => this.createRandomRoom2(),
        createAllRoom: () => this.createAllRoom(),
        activateCameraMovement: () => this.activateCameraMovement(),
        deactivateCameraMovement: () => this.deactivateCameraMovement(),
      };

      this.ui.add(this.actions, "GodMod");
      this.ui.add(this.actions, "TPToNFTRoom");
      this.ui.add(this.actions, "RemoveCollisions");
      this.ui.add(this.actions, "OpenAllDoors");
      this.ui.add(this.actions, "RemoveFog");
      this.ui.add(this.actions, "WatchFar");
      this.ui.add(this.actions, "WhiteBG");
      this.ui.add(this.actions, "AugmentedView");
      this.ui.add(this.actions, "createDevRoom");
      this.ui.add(this.actions, "createNFTRoom");
      this.ui.add(this.actions, "createScreamerRoom");
      this.ui.add(this.actions, "createRandomRoom1");
      this.ui.add(this.actions, "createRandomRoom2");
      this.ui.add(this.actions, "createAllRoom");
      this.ui.add(this.actions, "activateCameraMovement");
      this.ui.add(this.actions, "deactivateCameraMovement");
      this.ui
        .add(this.actions, "xRadiusCameraMovement")
        .min(-5)
        .max(5)
        .name("X Radius Camera Movement")
        .onChange((value) => {
          this.experience.world.character.defaultCameraXOffsetOnAction = value;
        });
      this.ui
        .add(this.actions, "xCameraOffset")
        .min(-5)
        .max(5)
        .name("X offsetCamera")
        .onChange((value) => {
          this.experience.world.character.defaultCameraOffset.x = value;
        });
      this.ui
        .add(this.actions, "yCameraOffset")
        .min(-5)
        .max(5)
        .name("Y offsetCamera")
        .onChange((value) => {
          this.experience.world.character.defaultCameraOffset.y = value;
        });
      this.ui
        .add(this.actions, "zCameraOffset")
        .min(-5)
        .max(5)
        .name("Z offsetCamera")
        .onChange((value) => {
          this.experience.world.character.defaultCameraOffset.z = value;
        });
      this.ui
        .add(this.actions, "cameraFOV")
        .min(10)
        .max(110)
        .name("Camera FOV")
        .onChange((value) => {
          this.experience.camera.instance.fov = value;
          this.experience.camera.instance.updateProjectionMatrix();
        });
    }
  }

  activateCameraMovement() {
    this.experience.world.character.hasCameraMovement = true;
  }

  deactivateCameraMovement() {
    this.experience.world.character.hasCameraMovement = false;
  }

  createNFTRoom() {
    this.experience.world.createNFTRoom();
  }

  createDevRoom() {
    this.experience.world.createDevRoom();
  }

  createScreamerRoom() {
    this.experience.world.createScreamerRoom();
  }

  createRandomRoom1() {
    this.experience.world.createRandomRoom1();
  }

  createRandomRoom2() {
    this.experience.world.createRandomRoom2();
  }

  createAllRoom() {
    this.createDevRoom();
    this.createNFTRoom();
    this.createScreamerRoom();
    this.createRandomRoom1();
    this.createRandomRoom2();
  }

  TPToNFTRoom() {
    this.experience.world.character.model.position.set(-38, -3, 9.72);
  }

  RemoveCollisions() {
    this.experience.world.character.canCollideList = [];
  }

  OpenAllDoors() {
    this.experience.world.door.animation.play("idle");
    this.experience.world.door1.animation.play("idle");
    this.experience.world.door2.animation.play("idle");
    this.experience.world.door3.animation.play("idle");
    this.experience.world.garageDoor.animation.play("anim");
    this.experience.world.door4.animation.play("idle");
  }

  RemoveFog() {
    this.experience.scene.fog.far = 30000;
  }

  WatchFar() {
    this.experience.camera.instance.far = 1000;
  }

  WhiteBG() {
    this.experience.scene.background = new THREE.Color("white");
  }

  AugmentedView() {
    this.experience.camera.instance.fov = 100;
    this.experience.camera.instance.updateProjectionMatrix();
  }

  GodMod() {
    this.OpenAllDoors();
    this.RemoveFog();
    this.WatchFar();
    this.WhiteBG();
    this.RemoveCollisions();
    this.AugmentedView();
  }
}
