import * as THREE from 'three'
import Experience from '../Experience'

export default class Tag {
    constructor(x, y, z, sx, sy, rx, ry, rz, textureName) {
        this.experience = new Experience()
        
        this.x = x
        this.y = y
        this.z = z

        this.sx = sx
        this.sy = sy

        this.rx = rx
        this.ry = ry
        this.rz = rz

        this.textureName = textureName

        this.init()
    }

    init() {
        this.setGeometry()
        this.setTexture()
        this.setModel()
    }

    setModel() {
        this.model = new THREE.Mesh(
            this.geometry,
            this.material
        )

        this.model.rotation.set(this.rx, this.ry, this.rz)
        this.model.position.set(this.x, this.y, this.z)
        this.experience.scene.add(this.model)
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(this.sx, this.sy)
    }

    setTexture() {
        this.material = new THREE.MeshBasicMaterial({
            map: this.experience.resources.items[this.textureName]
        })
        this.material.map.encoding = THREE.sRGBEncoding
    }
}