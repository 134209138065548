import Experience from "../Experience";
import gsap from 'gsap'

export default class Screamer
{
    constructor()
    {
        this.experience = new Experience()

        this.init()
    }

    init() {

    }

    setBlackScreen() {
        gsap.to(this.experience.renderer.effectController.bloom, {
            exposure: 0,
            onUpdate: () => {
                this.experience.renderer.instance.toneMappingExposure = Math.pow(this.experience.renderer.effectController.bloom.exposure, 4.0);
            }
        })
    }

    removeBlackScreen() {
        gsap.to(this.experience.renderer.effectController.bloom, {
            exposure: 1,
            duration: 5,
            onUpdate: () => {
                this.experience.renderer.instance.toneMappingExposure = Math.pow(this.experience.renderer.effectController.bloom.exposure, 4.0);
            }
        })
    }
    
    setCharacterOnFloor() {
        this.experience.world.character.lockUserActions()
        this.experience.world.character.animation.play('gettingUp')
        this.experience.world.character.animation.actions.current.paused = true
    }

    wakeUpCharacter() {
        
    }

    showImage() {
        if (this.experience.sounds.currentSounds.Screamer) {
            this.experience.sounds.currentSounds.Screamer.stop()
        }

        this.imageDOM = document.getElementById('screamer')
        this.imageDOM.style.display = "initial"

        gsap.to(this.imageDOM.style, {
            opacity: 1,
            duration: .02,
            delay: .1
        })
        
        clearInterval(this.experience.world.muteInterval);

        document.getElementById('screamerImg').addEventListener("ended", () => {
            gsap.to(this.imageDOM.style, {
                opacity: 0,
                duration: .1
            })
            
            this.experience.world.character.animation.actions.current.paused = false
            this.experience.sounds.currentSounds.Cellar.play()
            setTimeout(() => { this.experience.world.character.isLockedUserActions = false }, 1000)
        });
    }
}