import * as THREE from 'three'
import Experience from '../Experience.js'
import Cave from './Cave.js'
import Character from './Character.js'
import Door from './Door.js'
import Environment from './Environment.js'
import Item from './Item.js'
import gsap from 'gsap'
import TV from './TV.js'
import ArcadeMachineScreen from './ArcadeMachineScreen.js'
import ComputerScreen from './ComputerScreen.js'
import Screamer from '../Utils/Screamer.js'
import soundsObj from '../Utils/Sound.js'
import Tag from './Tag.js'
import ClickRaycaster from '../Utils/ClickRaycaster.js'
import MailBox from './MailBox.js'
import journalImg from '../../assets/img/journal.jpg'
import HackScreen from '../HackScreen.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.isStarted = false

        this.areOpenDoors = {
            a: false,
            b: false,
            c: false,
            d: false,
            e: false,
            disquette: false
        }

        this.itemsGeometry = new THREE.BufferGeometry()

        this.knifeIsTaken = false

        if (localStorage.getItem('accessITD')) {
            this.currentTry = parseInt( localStorage.getItem('accessITD')) + 1
            localStorage.setItem('accessITD', this.currentTry);
        } else {
            this.currentTry = 0
            localStorage.setItem('accessITD', this.currentTry);
        }

        const currentStep = 'intro' // intro, first_part, second_part, third_part
        this.arcadeMachineStep = 0
       
        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.environment = new Environment()
            this.experience.UI.homepage.btn.style.display = "initial"
            this.character = new Character(true)
            this.screamer = new Screamer()
            this.isStarted = true

            soundsObj.forEach((soundItem) => {
                this.experience.sounds.add(soundItem)
            })

        
            this.experience.UI.homepage.btn.style.cursor = "pointer"
            this.experience.UI.homepage.btn.addEventListener('click', () => this.experience.UI.startXP())
        
            this.clickRaycaster = new ClickRaycaster()
            this.HackScreen = new HackScreen()
            
            this.setComputerScreens()
        })
    }

    checkDoors() {
        if (this.areOpenDoors.a && this.areOpenDoors.b && this.areOpenDoors.c && this.areOpenDoors.e && this.areOpenDoors.disquette) {
            this.door1.initDOM()
        }
    }

    removeGarageColision() {
        this.character.canCollideList = this.character.canCollideList.filter((el) => el != this.garageDoor.model)
    }

    openInterrupteur() {
        // this.disquette.initDOM(-28.8, -1.5, 10.1, -26.7, 10.32, Math.PI/2, 'disquette');
        this.tv.initDOM(-9.27, -1, 19.32, -11.23, 19.42, -Math.PI/2, 'tv')
        this.lamp.openLight("light4")

        setTimeout(() => {
            this.lamp.closeLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.35, 4.0)
        }, 200)

        setTimeout(() => {
            this.lamp.openLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.85, 4.0)
        }, 300)

        setTimeout(() => {
            this.lamp.closeLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.35, 4.0)
        }, 800)

        setTimeout(() => {
            this.lamp.openLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.85, 4.0)
        }, 1000)

        setTimeout(() => {
            this.lamp.closeLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.35, 4.0)
        }, 1100)

        setTimeout(() => {
            this.lamp.openLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.85, 4.0)
        }, 1200)

        setTimeout(() => {
            this.lamp.closeLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.35, 4.0)
        }, 1400)

        setTimeout(() => {
            this.lamp.openLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.85, 4.0)
        }, 1800)

        setTimeout(() => {
            this.lamp.closeLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.35, 4.0)
        }, 2000)

        setTimeout(() => {
            this.lamp.openLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.85, 4.0)
        }, 2100)

        setTimeout(() => {
            this.lamp.closeLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.35, 4.0)
        }, 2200)

        setTimeout(() => {
            this.lamp.openLight("light4")
            this.experience.renderer.instance.toneMappingExposure = Math.pow(0.85, 4.0)
        }, 2700)
        this.experience.renderer.instance.toneMappingExposure = Math.pow(0.85, 4.0)
        // this.experience.renderer.instance.needsUpdate()
        this.experience.sounds.play('LightNeon')
        this.scifiLight11.model.visible = true
        this.door.material.color.r = 2; this.door.material.color.g = 2; this.door.material.color.b = 2; 
        this.updateAllMat()
    }

    takeKnife() {
        var s = s_gi('wmg,wmgfr');						
        s_dtm.linkTrackVars='prop1,eVar4';						
        s_dtm.prop1='menace Santana';						
        s_dtm.eVar4 = 'menace Santana';						
        s_dtm.tl(this,'o','menace Santana:Into The Dark 3D NFT Game:Landing:Take Butterfly Knife Click');						

        this.character.isLockedUserActions = true
        this.character.animation.play('walking')

        let finalCharacterPosition = new THREE.Vector3(-20.1181, 0, 31.39800)
        let finalCharacterRotation = new THREE.Vector3()
        finalCharacterRotation.y =  -Math.PI/2

        // Determine the angle
        let differencePosition = new THREE.Vector3().subVectors(finalCharacterPosition,  this.character.model.position )
        let InitialRotationY = this.character.model.rotation.y
        let aCosAngleCharacter =  Math.atan(
            differencePosition.x / differencePosition.z
        )

        // Rotate character to the item
        if (differencePosition.z >= 0) {
            if (differencePosition.x < 0) {
                aCosAngleCharacter += Math.PI
            } else {
                aCosAngleCharacter += Math.PI
            }
        }

        aCosAngleCharacter += Math.PI*2 * Math.floor(InitialRotationY/Math.PI*2)
        if (aCosAngleCharacter - InitialRotationY < -Math.PI) {
            aCosAngleCharacter += Math.PI*2
        } else if (aCosAngleCharacter - InitialRotationY > Math.PI) {
            aCosAngleCharacter -= Math.PI*2
        }

        // Maker the character look at the object
        gsap.to(
            this.character.model.rotation, {
                y: aCosAngleCharacter,
                duration: 0.4,
                ease: 'linear'
            }
        )  

        // Move the character to the item
        this.character.playSound()
        gsap.to(this.character.model.position, {
            x: finalCharacterPosition.x,
            z: finalCharacterPosition.z,
            duration: this.character.model.position.distanceTo(finalCharacterPosition.x ? {
                x: finalCharacterPosition.x,
                y: -0.3,
                z: finalCharacterPosition.z
            }: {
                x: -20.1181, y: -2.98999, z: 31.39800
            }) / 3,
            ease: 'linear',
            onComplete: () => {
                this.character.removeSound()
                if (finalCharacterRotation.y > this.character.model.rotation.z) {
                    this.character.animation.play('turnLeft')
                } else {
                    this.character.animation.play('turnRight')
                }

                let finalRotation = finalCharacterRotation.y
                let characterRotationI = this.character.model.rotation.y

                finalRotation += Math.PI*2 * Math.floor(
                    characterRotationI / Math.PI*2
                )

                if (finalRotation - characterRotationI < -Math.PI) {
                    finalRotation += Math.PI*2
                } else if (finalRotation - characterRotationI > Math.PI) {
                    finalRotation -= Math.PI*2
                }

                gsap.to(this.character.model.rotation, {
                    duration: 1,
                    y: finalRotation,
                    ease: 'easeOut',
                    onComplete: () => {
                        this.character.animation.play('pickObject')

                        setTimeout(() => {
                            this.butterflyKnife.model.visible = false
                            this.knifeIsTaken = true
                            this.door4.initDOM()
                            this.character.defaultCameraOffset.x = 0
                            this.character.isLockedUserActions = false
                        }, 1000)
                    }
                })
            }
        })
    }

    addScene1() {
        this.cave = new Cave({ x: -8.1, y: -3.6, z: 24 }, { x: 0, y: 0, z: 0 }, 0)
        this.addPostersAndTags()
        this.colisions = new Item('ColisionsModel', {  x: -8.1, y: -3.4, z: 24}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, null, null, null)
        this.character.canCollideList.push(this.colisions.model)
        this.testmaterial = new THREE.MeshBasicMaterial({ visible: false })
        this.colisions.model.children[0].material = this.testmaterial

        this.poubelles = new Item('PoubellesModel', {  x: -8.1, y: -3.4, z: 24}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "PoubellesTexture", null, null)
        this.butterflyKnife = new Item('ButterflyKnife', {  x: -18.1, y: -0.44999, z: 31.4}, {x: Math.PI/2 - Math.PI/10, y: Math.PI/2, z: 0}, {x: 0.05, y: 0.05, z: 0.05}, "ButterflyKnifeTexture", null, null)
        this.butterflyKnife.model.traverse((children) => {
            children.customData = () => {
                this.takeKnife()
            }
            this.experience.interactiveElements.push(children)
        })

        this.setDoors()
        this.setLights()

        this.armoire2 = new Item( "armoireModel", {x: -9.3, y: -3, z: 19.42}, {x: 0, y: -Math.PI/2, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, null, null, null)
        this.lamp = new Item( "interrupeurModel", {x: -8.84, y: -1.3, z: 22.62}, {x: 0, y: 0, z: 0}, {x: .15, y: .15, z: .15}, null, null, null, () => {
            this.openInterrupteur()
        }, true)

        this.tv = new TV( "radioModel", {x: -9.27, y: -.9, z: 19.42}, {x: 0, y: 0, z: 0}, {x: 2.5, y: 2.5, z: 2.5}, null, null, null, () => {
            this.turnOnTV();
        }, true, true, () => {
            this.startScreamerVideo()
        })
        
        this.tv.model.children[0].rotation.z = -Math.PI/2
        this.velo = new Item( "veloModel", {x: -12.369, y: -2.1, z: 27.1}, {x: 0, y: 0, z: 0}, {x: 0.25, y: 0.25, z: 0.25}, null, null, null, () => {}, true)
        this.scifiLight11 = new Item( "scifiLight", {x: -17.42, y: 2.1, z: 2.237}, {x: Math.PI, y: 0, z: 0}, {x: .01, y: .01, z: .02}, null, null, null, () => {}, true)
        this.scifiLight11.model.visible = false
        this.setArcadeMachine()
    }

    setLights() {
        this.light = new THREE.PointLight(0xff0000, 10, 6)
        this.light.castShadow = false
        this.scene.add(this.light)
        this.light.position.set(-25.7, 2, 31)
        this.clignoteLight()
        
        this.light4 = new THREE.PointLight(0xffffff, 0, 8)
        this.light4.castShadow = false
        this.scene.add(this.light4)
        this.light4.position.set(-10.85, 1.9, 17.03)
    }

    updateAllMat() {
        this.environment.environmentMap.intensity = 1.9
        this.environment.environmentMap.updateMaterials()
    }

    setDoors() {
        this.door = new Door( { x: -15.01, y: -0.3, z: 19.75 }, { x: 0, y: Math.PI, z: 0 }, { x: 0.5, y: 0.68, z: 0.5 }, false, () => { this.areOpenDoors.b = true;   this.checkDoors(); this.setScifiLights(); this.openDoor();  
            
            if (parseInt(localStorage.getItem('accessITD')) <= 3) {
                this.powerBox.initDOM(-20.1, 0, 22.012, -18.786, 22.09448, Math.PI/2, 'powerBox')
            }
            

            if (parseInt(localStorage.getItem('accessITD')) > 3) {
                var s = s_gi('wmg,wmgfr');						
                s_dtm.linkTrackVars='prop1,eVar4';						
                s_dtm.prop1='menace Santana';						
                s_dtm.eVar4 = 'menace Santana';						
                s_dtm.tl(this,'o','menace Santana:Into The Dark 3D NFT Game:Landing:Launch Exclusive Sound Click');						

                this.experience.sounds.play('IlEtaitUneFois')
            }
        }, 'door')
        this.door2 = new Door( { x: -24.422, y: -0.3, z: 12.73 }, { x: 0, y: Math.PI/2, z: 0 }, { x: 0.5, y: 0.7, z: 0.6 }, false, () => {
            this.areOpenDoors.a = true; 
            this.checkDoors();
            this.createScreamerRoom()
            ; setTimeout(() => { this.disquette.initDOM(-28.8, -1.5, 10.1, -26.7, 10.32, Math.PI/2, 'disquette'); }, 3000)
        }, 'door2')
        this.door1 = new Door( { x: -35.79, y: -0.3, z: 26.96 }, { x: 0, y: 0, z: 0 }, { x: 0.65, y: 0.7, z: 0.65 }, false, () => {
            this.createDevRoom();
            this.devRoom.initDOM(-29.713, -2, 27.47, -31.713, 23.47, -Math.PI/2, 'devRoom');
        }, 'door1' )
        this.door3 = new Door( { x: -41.1, y: -0.3, z: 24.75 }, { x: 0, y: Math.PI, z: 0 }, { x: 0.65, y: 0.7, z: 0.65 }, false, () => {   this.areOpenDoors.c = true; this.createRandomRoom1(); this.checkDoors(); this.journaux.initDOM(-44.85, -0.39, 24.87, -43.85, 24.3, Math.PI/2, 'journaux');}, 'door3' )
        this.door4 = new Door( { x: -38.86, y: -0.3, z: 31 }, { x: 0, y: -Math.PI/2, z: 0 }, { x: 0.65, y: 0.7, z: 0.65 }, false, () => {this.areOpenDoors.d = true; this.createRandomRoom2(); }, 'door4' )
    }

    checkArcadeMachine() {
        if (this.arcadeMachineStep === 0) {
            var s = s_gi('wmg,wmgfr');						
            s_dtm.linkTrackVars='prop1,eVar4';						
            s_dtm.prop1='menace Santana';						
            s_dtm.eVar4 = 'menace Santana';						
            s_dtm.tl(this,'o','menace Santana:Into The Dark 3D NFT Game:Landing:Open Tracklist Click');						

            
            this.arcadeMachineStep = 1
            this.arcadeMachine.model.children[1].material.uniforms.texture1.value = this.experience.resources.items.secondArcadeScreen
        } else if (this.arcadeMachineStep === 1) {
            if (this.areOpenDoors.disquette === true) {
                var s = s_gi('wmg,wmgfr');						
                s_dtm.linkTrackVars='prop1,eVar4';						
                s_dtm.prop1='menace Santana';						
                s_dtm.eVar4 = 'menace Santana';						
                s_dtm.tl(this,'o','menace Santana:Into The Dark 3D NFT Game:Landing:Open Arcade Machine Click');						

                this.arcadeMachineStep = 2
                this.arcadeMachine.model.children[1].material.uniforms.texture1.value = this.experience.resources.items.thirdArcadeScreen
            }
        }
    }

    setArcadeMachine() {
        this.arcadeMachineScreen = new ArcadeMachineScreen()
        this.arcadeMachine = new Item('arcadeMachineModel', {x: -13.8, y: -3, z: 17}, {x: 0, y: 0, z: 0}, {x: 0.5, y: 0.5, z: 0.5}, 'arcadeMachineColor', null, null, () => {}, true)
        this.arcadeMachine.model.traverse((children) => {
            children.customData = () => this.checkArcadeMachine()
            this.experience.interactiveElements.push(children)
        })
        this.arcadeMachine.model.children[1].material = this.computerScreen.material
        this.arcadeMachine.model.children[1].material.side = THREE.DoubleSide
        this.arcadeMachine.model.children[1].rotation.z = 0
    }

    setComputerScreens() {
        this.computerScreen = new ComputerScreen()
    }

    openDoor() {
        this.door3.initDOM()
        this.door2.initDOM()
    }

    startScreamerVideo() {
        this.revideo = document.getElementById('screamerImg')
        this.revideo.muted = false;
        this.revideo.volume = 1;
        // this.revideo.autoplay = true;
        this.revideo.play();
        
        this.muteInterval = setInterval(() => {
            this.revideo.currentTime = 0;
        }, 10);
    }

    takeDisquette() {
        this.areOpenDoors.disquette = true
        this.checkDoors()
        this.disquette.model.visible = false
        // this.character.model.position.x += 3
        // this.character.animation.play('gettingUp')
    }

    createScreamerRoom() {
        this.screamerRoom1 = new Item( "ScreamerRoomModel", { x: -8.1, y: -3.6, z: 24}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "ScreamerRoomTexture", null, null, () => {}, true)
        this.screamerRoom1.model.material.side = THREE.DoubleSide
        this.disquette = new Item( "DisquetteModel", { x: -28.8, y: -1.5, z: 10.1}, {x: -Math.PI/2, y: 0, z: -2.07}, {x: .15, y: .15, z: .15}, "DisquetteTexture", null, null, () => {
            this.takeDisquette()
            
            if (parseInt(localStorage.getItem('accessITD')) <= 3) {
                if (this.experience.sounds.currentSounds.HeightBitSlow) {
                    this.experience.sounds.currentSounds.HeightBitSlow.pause()
                }
                
                this.experience.sounds.play('HeightBitMid')
            }
        }, true, true, () => {
            
        this.character.createCinematique(this.disquette.model.position.x, this.disquette.model.position.z, 3, 0, this.disquette.model, 1, 1, 50) 
        })
        this.disquette.model.material.side = THREE.DoubleSide

        this.updateAllMat()
    }



    createRandomRoom1() {
        this.objects1 = new Item( "Objects1Model", {x: -8.1, y: -3.6, z: 23.92}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "Objects1Texture", null, null)
        this.journaux = new Item( "JournauxModel", {x: -44.85, y: -0.39, z: 24.17}, {x: 0, y: 0, z: 1.5707963267948966}, {x: 0.4, y: 0.4, z: 0.4}, null, null, null, () => {
            this.clickRaycaster.showImage(journalImg)
            this.clickRaycaster.openInteractiveDOM()
        }, true, true)
        this.cartonsRoom1 = new Item( "CartonsRoomModel", { x: -8.1, y: -3.65, z: 24}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "CartonsRoomTexture", null, null, () => {}, true, true)
        this.updateAllMat()
    }

    createRandomRoom2() {
        this.cartonsRoom2 = new Item( "CartonsRoomModel2", { x: -8.1, y: -3.6, z: 24}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "CartonsRoomTexture2", null, null, () => {
            this.experience.sounds.play('Radio')
            this.experience.sounds.currentSounds.Radio.on('end', () => {
                this.experience.sounds.currentSounds.Cellar.play()
            })
        }, true, true)
        
        // this.cartonsRoom2.initDOM(-38.15, -1.5, 38.725, -38.15, 36.09, Math.PI, 'cartonsRoom2')
        
        
        this.disque = new Item( "Disque", {x:  -32.25, y: -1.7, z: 34.0249999}, {x: 0, y: Math.PI/3, z: 0}, {x: .2, y: .2, z: .2}, null, null, null)
       
        this.updateAllMat()
    }

    addPostersAndTags() {
        this.bloodyHand = new Tag(-20.4, 0.1, 23.5, 0.75, 0.75, 0, Math.PI/2, -Math.PI/50, "BloodyHand")
        this.bloodyHand.model.material.transparent = true
        this.bloodyHand.model.material.opacity = 0.6
    }
    
    createNFTRoom() {
        
        this.bd = new Item( "BD", {x: -43.2, y: -1.22, z: 10.6799}, {x: 0, y: -Math.PI - Math.PI/7, z: 0}, {x: 0.1, y: 0.17, z: 0.25}, null, null, null)

        this.mailBox = new MailBox()
        this.objects2 = new Item( "Objects2Model", {x: -8.1, y: -3.46, z: 24.08}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "Objects2Texture", null, null)
        this.NFTRoom_1 = new Item( "NFTRoom_1_Model", {x: -8.1, y: -3.5, z: 24}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "NFTRoom_1_Texture", null, null, () => {}, true, true)
        this.NFTRoom_2 = new Item( "NFTRoom_2_Model", {x: -6.1, y: -3.57, z: 24.9}, {x: 0, y: 0, z: 0}, {x: 1.9, y: 1.9, z: 1.9}, "NFTRoom_2_Texture", null, null, () => {
            this.mailBox.openMailBox()
        }, true, true)
        
        this.NFTRoom_2.model.children.find((child) => child.name === "Screen1001").material = this.computerScreen.material.clone()
        this.NFTRoom_2.model.children.find((child) => child.name === "Screen1001").material.uniforms.texture1.value = this.resources.items.Salle3Ecran1
        this.NFTRoom_2.model.children.find((child) => child.name === "Screen1001").scale.set(0.98, 0.98, 0.98)
        this.NFTRoom_2.model.children.find((child) => child.name === "Screen1001").position.set(-0.37, 0.04, -0.2)

        this.character.canCollideList.push(this.NFTRoom_1.model)
        this.NFTRoom_1.model.material.side = THREE.DoubleSide
        this.NFTRoom_1.model.children.find((child) => child.name === "TVScreen").material = this.tv.model.children[0].children[0].children[0].material.clone()
        this.NFTRoom_1.model.children.find((child) => child.name === "TVScreen").material.side = THREE.DoubleSide
    
        this.updateAllMat()
    }

    createDevRoom() {
        this.devRoom = new Item( "DevRoomModel", { x: -8.1, y: -3.4, z: 24}, {x: 0, y: 0, z: 0}, {x: 1.8, y: 1.8, z: 1.8}, "DevRoomTexture", null, null, () => { this.HackScreen.openDOM(); this.HackScreen.initCode() }, true, true)
        
        this.devRoom.model.children.forEach((children) => {
            if (children.name === "Screen1" || children.name === "Screen2" || children.name === "Screen3") {
                children.material = this.tv.model.children[0].children[0].children[0].material.clone()
                children.material.side = THREE.DoubleSide
            }
        })

        this.updateAllMat()
    }
    
    turnOnTV() {
        
        this.character.lockUserActions()
        this.mainDOM = document.getElementById('screamer')
        this.mainDOM.style.opacity = 0
        gsap.to(this.mainDOM.style, {
            opacity: 1,
            duration: .0
        })
        setTimeout(() => { this.screamer.showImage() }, 100)
        this.character.animation.actions.current.paused = true

        this.tv.setTVMaterial()
        this.tv.setScreen()
        // this.experience.audioPlayer.part1Sound.play()
        this.door.initDOM()
        
        this.garageDoor = new Item( "GarageDoorModel", { x: -8.05, y: -3.5, z: 23.95}, {x: 0, y: 0, z:0}, {x: 1.8, y: 1.8, z: 1.8}, null, null, null, () => {
            this.removeGarageColision()
            this.character.lockUserActions()
            this.experience.sounds.play('Garage')
            this.garageDoor.animation.play('anim')
            this.areOpenDoors.e = true;
            this.NFTRoom_2.initDOM(-41.38, -1.5, 7.42, -41.38, 8.8, 0, 'NFTRoom_2')
            this.checkDoors()
        }, true)
        
        this.garageDoor.model.children[0].material.metalness = 1
        this.garageDoor.initDOM(-38.4, -1, 13.33, -38.4, 17.8, 0, 'garageDoor')
        this.character.canCollideList.push(this.garageDoor.model)
        this.experience.sounds.currentSounds.LightNeon.pause()

        setTimeout(() => {
            this.createNFTRoom()
        }, 5000)
        // this.experience.sounds.currentSounds.Cellar.pause()
    }

    clignoteLight() {
        gsap.to(this.light, { duration: 0.0, intensity: 0, delay: 0 })
        gsap.to(this.light, { duration: .0, intensity: 8, delay: 0.4 })
        gsap.to(this.light, { duration: .00, intensity: 2, delay: 0.6 })
        gsap.to(this.light, { duration: .00, intensity: 12, delay: 0.9 })
        gsap.to(this.light, { duration: .4, intensity: 20, delay: 1 })
        gsap.to(this.light, { duration: .4, intensity: 8, delay: 1.4,
            onComplete: () => { setTimeout(() => { this.clignoteLight() }, Math.random() * 5000) }
        })
    }

    setScifiLights() {
        this.powerBox = new Item( "powerBox", {x: -20.3, y: 0.1, z: 22.25}, {x: 0, y: Math.PI/2, z: 0}, {x: 2.2, y: 2.2, z: 2.2}, null, null, null, () => {
            this.experience.sounds.play('HeightBitSlow')
        }, true)
        this.scifiLight = new Item( "scifiLight", {x: -47.827, y: 2.2, z: 22.2}, {x: Math.PI, y: 0, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight2 = new Item( "scifiLight", {x: -17.9, y: 2.2, z: 17.55}, {x: Math.PI, y: Math.PI/2, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight3 = new Item( "scifiLight", {x: -15.65, y: 2.2, z: 26.9}, {x: Math.PI, y: 0, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight4 = new Item( "scifiLight", {x: -15.65, y: 2.2, z: 31}, {x: Math.PI, y: 0, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight5 = new Item( "scifiLight", {x: -27.26, y: 2.2, z: 13.24}, {x: Math.PI, y: Math.PI/2, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight6 = new Item( "scifiLight", {x: -11.34, y: 2.2, z: 29.2}, {x: Math.PI, y: Math.PI/2, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight7 = new Item( "scifiLight", {x: -22.6, y: 2.2, z: 23.8}, {x: Math.PI, y: 0, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight8 = new Item( "scifiLight", {x: -36.06, y: 2.2, z: 24.23}, {x: Math.PI, y: Math.PI, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight9 = new Item( "scifiLight", {x: -36.06, y: 2.2, z: 20.83}, {x: Math.PI, y: Math.PI, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight10 = new Item( "scifiLight", {x: -38.588, y: 2.2, z: 40.35}, {x: Math.PI, y: Math.PI/2, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
        this.scifiLight12 = new Item( "scifiLight", {x: -41, y: 2.2, z: 15.94}, {x: Math.PI, y: 0, z: 0}, {x: .01, y: .01, z: .02}, null, null, null)
    }
    
    endXP() {
        // this.character.lockUserActions()
        // this.disquette.model.position.set(
        //     this.character.model.position.x,
        //     -1,
        //     this.character.model.position.z
        // )
        
        // this.disquette.model.scale.set(0.12, 0.12, 0.12)
        // this.disquette.model.rotation.set(Math.PI, -Math.PI/2, 0)
        // this.disquette.model.visible = true

        // gsap.to(this.disquette.model.position, {
        //     x: -29.713,
        //     y: 0,
        //     z: 27.47,
        //     delay: 2,
        //     duration: 2,
        //     onComplete: () => {
        //         gsap.to(this.disquette.model.position, {
        //             x: -29.713,
        //             y: -5,
        //             z: 27.47,
        //             duration: 3,
        //             onComplete: () => {
        //             }
        //         })
        //     }
        // })

        
        this.experience.renderer.addGlitchPass()
        this.experience.sounds.play("EndGlitch")
        this.experience.renderer.postprocessing.glitchPass.goWild = true
        
        setTimeout(() => {
            this.experience.UI.setBlackScreen()
        }, 4000)

        setTimeout(() => {
            this.experience.UI.openEndPage()
        }, 6000)

        setTimeout(() => {
            this.experience.UI.fadeOutScreen()
        }, 7000)
    }

    update()
    {

        if (this.isStarted) {
            if (this.character) 
                this.character.update()

            if (this.door) 
                this.door.update()

            if (this.tv)
                this.tv.update()
                
            if (this.door2) 
                this.door2.update()
                
            if (this.door3) 
                this.door3.update()
            
            if (this.door1) 
                this.door1.update()
            
            if (this.door4) 
                this.door4.update()

            if (this.arcadeMachineScreen) {
                this.arcadeMachineScreen.update()
            }

            if (this.garageDoor) {
                this.garageDoor.update()
            }

            if (this.NFTRoom_1) {
                this.NFTRoom_1.model.children.filter((child) => child.name.startsWith('APale')).forEach((child) => {
                    child.rotation.z += 0.1
                })
            }

            if (this.NFTRoom_2) {
                this.NFTRoom_2.model.children.filter((child) => child.userData.name.startsWith("Pale")).forEach((child) => {
                    child.rotation.z += 0.1
                })
            }
        }
    }
}