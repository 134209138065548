import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap'
import { Vector3 } from 'three'

const PI2 = Math.PI * 2

export default class Door
{
    constructor(position, rotation, scale, canBeOpen, onOpen, name)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.position = position
        this.rotation = rotation
        this.scale = scale

        this.canBeOpen = canBeOpen
        this.onOpen = onOpen

        this.name = name

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Door')
        }

        // Resource
        this.resource = this.resources.items.DoorModel

        if (!this.experience.world.door) {
            this.setTextures()
        }

        this.setMaterial()
        this.setModel()
        this.setAnimation()

        if (this.canBeOpen)
            this.initDOM()
    }

    setTextures() {
        this.textures = {}

        this.textures.color = this.resources.items.DoorColor
        this.textures.color.encoding = THREE.sRGBEncoding
        // this.textures.color.minFilter = THREE.NearestFilter
        // this.textures.color.magFilter = THREE.NearestFilter
    
        this.textures.color.flipY = false
    }

    setMaterial() {
        this.material = this.experience.world.door ? this.experience.world.door.material : new THREE.MeshBasicMaterial({
            map: this.textures.color,
            side: THREE.DoubleSide
        })

        this.material.color.r = 0.5
        this.material.color.g = 0.5
        this.material.color.b = 0.5

    }

    setModel()
    {
        this.model = this.experience.world.door ?  this.experience.world.door.model.clone() : this.resource.scene.clone()
        this.experience.renderer.instance.compile(this.model, this.experience.camera.instance)
        this.model.position.set(this.position.x, this.position.y, this.position.z)
        this.model.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
        this.model.scale.set(this.scale.x, this.scale.y, this.scale.z)
       
        this.model.name = "Door"
        this.scene.add(this.model)
        this.model.material = this.material

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.material = this.material
                child.receiveShadow = false
            }
        })

        this.experience.world.character.canCollideList.push(this.model)

        if (this.debug.active) {
            this.debugFolder.add(this.model.position, 'x').min(0).max(80)
            this.debugFolder.add(this.model.position, 'z').min(0).max(70)
            this.debugFolder.add(this.model.rotation, 'y').min(-Math.PI).max(Math.PI)
            this.debugFolder.close()
        }
    }
    setAnimation()
    {
        this.animation = {}
        
        // Mixer
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        
        // Actions
        this.animation.actions = {}
        
        this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0])

        this.animation.actions.current = this.animation.actions.idle
        
        this.animation.actions.idle.setLoop(THREE.LoopOnce);
        this.animation.actions.idle.clampWhenFinished = true;

        // Play the action
        this.animation.play = (name) =>
        {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 0)

            this.animation.actions.current = newAction
        }

        // Debug
        if(this.debug.active)
        {
            const debugObject = {
                playIdle: () => { this.animation.play('idle') }
            }
            this.debugFolder.add(debugObject, 'playIdle')
        }
    }

    initDOM() {
        this.doorPointsContainer = document.getElementById('doorPoints')

        const doorPointDiv = document.createElement("div");
        doorPointDiv.setAttribute("class", `point point-${this.name}`);

        const doorLabel = document.createElement("div");
        doorLabel.setAttribute("class", `label`);

        doorPointDiv.appendChild(doorLabel)

        this.doorPointsContainer.appendChild(doorPointDiv);

        doorLabel.addEventListener('click', () => {
            if (this.experience.world.character.isLockedUserActions === false) {
                
            if (this.onOpen) {
                this.onOpen()
            }
            this.experience.world.character.lockUserActions()
            this.experience.world.character.animation.play('walking')

            let finalCharacterPosition = new THREE.Vector3()
            let finalCharacterRotation = new THREE.Vector3()

            if (this.model.rotation.y === Math.PI / 2) {
                finalCharacterPosition.z = this.model.position.z + 1
                finalCharacterPosition.x = this.model.position.x
                finalCharacterRotation.y = 0
            } else if (this.model.rotation.y === -Math.PI / 2) {
                finalCharacterPosition.z = this.model.position.z - 1
                finalCharacterPosition.x = this.model.position.x
                finalCharacterRotation.y = Math.PI
            } else if (this.model.rotation.y === Math.PI) {
                finalCharacterPosition.z = this.model.position.z
                finalCharacterPosition.x = this.model.position.x + 1
                finalCharacterRotation.y = Math.PI / 2
            } else if (this.model.rotation.y === 0) {
                finalCharacterPosition.z = this.model.position.z
                finalCharacterPosition.x = this.model.position.x - 1
                finalCharacterRotation.y = - Math.PI / 2
            }
            
            // Determine the angle
            let differencePosition = new THREE.Vector3().subVectors(finalCharacterPosition,  this.experience.world.character.model.position )
            let InitialRotationY = this.experience.world.character.model.rotation.y
            let aCosAngleCharacter =  Math.atan(
                differencePosition.x / differencePosition.z
            )

            // Rotate character to the item
            if (differencePosition.z >= 0) {
                if (differencePosition.x < 0) {
                    aCosAngleCharacter += Math.PI
                } else {
                    aCosAngleCharacter += Math.PI
                }
            }

            aCosAngleCharacter += PI2 * Math.floor(InitialRotationY/PI2)
            if (aCosAngleCharacter - InitialRotationY < -Math.PI) {
                aCosAngleCharacter += PI2
            } else if (aCosAngleCharacter - InitialRotationY > Math.PI) {
                aCosAngleCharacter -= PI2
            }

            gsap.to(this.experience.world.character.model.rotation, {
                y: aCosAngleCharacter,
                duration: 0.4,
                ease: 'linear'
            })
            
            this.experience.points = this.experience.points.filter((el) => el.element !== doorPointDiv)

            
            this.experience.world.character.playSound()

            gsap.to(this.experience.world.character.model.position, {
                x: finalCharacterPosition.x,
                z: finalCharacterPosition.z,
                duration: this.experience.world.character.model.position.distanceTo(this.model.position) / 2,
                ease: 'linear',
                onComplete: () => {
                    this.experience.world.character.removeSound()
                    if (finalCharacterRotation.y > this.experience.world.character.model.rotation.z) {
                        this.experience.world.character.animation.play('turnLeft')
                    } else {
                        this.experience.world.character.animation.play('turnRight')
                    }

                    let finalRotation = finalCharacterRotation.y
                    let characterRotationI = this.experience.world.character.model.rotation.y

                    finalRotation += PI2 * Math.floor(
                        characterRotationI / PI2
                    )

                    if (finalRotation - characterRotationI < -Math.PI) {
                        finalRotation += PI2
                    } else if (finalRotation - characterRotationI > Math.PI) {
                        finalRotation -= PI2
                    }

                    gsap.to(this.experience.world.character.model.rotation, {
                        duration: 1,
                        y: finalRotation,
                        ease: 'easeOut',
                        onComplete: () => {
                            this.experience.world.character.animation.play('pickObject')
                            
                            setTimeout(() => {
                                this.experience.sounds.play('Portes', 2)
                            }, 800 )
                        
                            setTimeout(() => {
                                this.animation.play('idle')
                                this.experience.world.character.isLockedUserActions = false
                            }, 1200)
                        }
                    })
                }
            })
            
            doorPointDiv.classList.remove('visible')
            }
        })

        this.experience.points.push({
            position: new THREE.Vector3(this.model.position.x, this.model.position.y, this.model.position.z),
            element: doorPointDiv
        })
    }

    update()
    {
        this.animation.mixer.update(this.time.delta * 0.001)
    }
}